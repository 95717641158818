import {
  ACTION_LOGIN,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGOUT,
  ACTION_LOGOUT_SUCCESS,
  ACTION_LOGIN_RESET,
  ACTION_LOGOUT_NOT_ALLOW,
  ACTION_CLICK_MENU_FIRST,
} from 'src/redux/actions/login/ELoginActions'

const INITIAL_STATE = {
  data: {},
  loading: false,
  isReset: false,
  isFirstClickMenu: true,
  moduleAccess: [],
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_LOGIN:
      return { ...state, loading: true }

    case ACTION_LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        moduleAccess: action.payload?.result?.user?.role?.module_access,
        loading: false,
        isReset: false,
        isFirstClickMenu: true,
      }

    case ACTION_LOGOUT:
      return { ...state, loading: true }

    case ACTION_LOGOUT_NOT_ALLOW:
    case ACTION_LOGOUT_SUCCESS:
      return {
        ...state,
        data: {},
        loading: false,
      }
    case ACTION_LOGIN_RESET:
      return {
        ...state,
        isReset: true,
      }
    case ACTION_CLICK_MENU_FIRST:
      return {
        ...state,
        isFirstClickMenu: false,
      }
    default:
      return state
  }
}

export default loginReducer
