import React, { useState } from 'react'
import { CButton } from '@coreui/react'
import { useTranslation } from 'react-i18next'
import icon from 'src/assets/images/iconToTop.svg'

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 0) {
      setVisible(true)
    } else if (scrolled <= 0) {
      setVisible(false)
    }
  }

  const style = {
    position: 'fixed',
    // width: '100%',
    right: '40px',
    bottom: '40px',
    height: '45px',
    fontSize: '13px',
    zIndex: '1',
    cursor: 'pointer',
    background: '#444444',
    border: '1px solid #D9DBDF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '7px',
    padding: '10px',
    fontWeight: '600',
    color: '#FFFFFF',
    lineHeight: '19px',
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <div style={{ display: visible ? 'inline' : 'none' }}>
      <CButton
        onClick={scrollToTop}
        style={style}
        className="d-flex align-items-center justify-content-center"
      >
        <img src={icon} alt="" style={{ marginRight: '10px' }} />
        {t('scrollToTop')}
      </CButton>
    </div>
  )
}

export default ScrollToTop
