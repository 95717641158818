const CHECK_EMAIL_ACTION = 'CHECK_EMAIL_ACTION'
const CHECK_EMAIL_SUCCESS_ACTION = 'CHECK_EMAIL_SUCCESS_ACTION'
const RESET_PASSWORD_ACTION = 'RESET_PASSWORD_ACTION'
const RESET_PASSWORD_SUCCESS_ACTION = 'RESET_PASSWORD_SUCCESS_ACTION'
const RESET_ACTION = 'RESET_ACTION'
const ACTION_CHECK_TOKEN_RESET_PASSWORD = 'ACTION_CHECK_TOKEN_RESET_PASSWORD'
const ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS = 'ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS'

export {
  CHECK_EMAIL_ACTION,
  CHECK_EMAIL_SUCCESS_ACTION,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_SUCCESS_ACTION,
  RESET_ACTION,
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
}
