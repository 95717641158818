const TIME_OUT = {
  DELAY_CALL_API: 5000,
  RESET_ACTION: 3000,
  DELAY_MODAL: 500,
  DELAY_CHANGE_MY_ROLE_SUCCESS: 1000,
}

const SAVE_TYPE_NOTIFICATION = {
  DRAFT: 1,
  SAVE_AND_SEND: 2,
}

const STATUS_NOTIFICATION = {
  DRAFT: 0,
  DELIVERY_RESERVATION: 1,
  DELIVERED: 2,
}

const STATUS_NOTICE_LIST = {
  DRAFT: 1,
  DELIVERY_TIME: 2,
  SENDING_OR_SENT: 3,
}

const TYPE_EMAIL = {
  DESIGNATED_DELIVERY: 1,
  LIST_DISTRIBUTION: 2,
}

const TYPE_DELIVERY_DATE = {
  DELIVERY_RESERVATION: 2,
  IMMEDIATE_DELIVERY: 1,
}

const STATUS_REQUEST = {
  NOT_COMPATIBLE: 0,
  DURING_CORRESPONDENCE: 1,
  DONE: 2,
}

const TYPE_ADMIN = {
  ADMIN_SYSTEM: 1,
  HEAD_OFFICE_MANAGER: 2,
  SALE_OFFICE_MANAGER: 3,
}

const CHOOSE_CONTRACT = {
  CONTRACT_PULL_DOWN_SELECTABLE: 1,
  CONTRACT_PULL_DOWN_SELECTABLE_FIXED: 2,
  CONTRACT_PULL_DOWN_SELECTABLE_HIDDEN: 3,
}

const PERMISSION_ADMIN = {
  VIEW_NOTIFICATION: 0,
  CUD_NOTIFICATION: 1,
  VIEW_REQUEST_FROM: 2,
  CUD_REQUEST_FROM: 3,
  VIEW_ADMIN: 4,
  CUD_ADMIN: 5,
}

const TYPE_FILE = {
  PDF: 'pdf',
  CSV: 'csv',
  TXT: 'txt',
  IMAGE_PC: 'pc',
  IMAGE_MOBILE: 'mob',
}

const STATUS_SEND_MAIL = {
  NOT_SEND: 0,
  SENDING: 1,
  DONE: 2,
}

export {
  TIME_OUT,
  SAVE_TYPE_NOTIFICATION,
  STATUS_NOTIFICATION,
  STATUS_NOTICE_LIST,
  TYPE_EMAIL,
  TYPE_DELIVERY_DATE,
  STATUS_REQUEST,
  TYPE_ADMIN,
  PERMISSION_ADMIN,
  TYPE_FILE,
  CHOOSE_CONTRACT,
  STATUS_SEND_MAIL,
}
