import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeader, CHeaderNav, CHeaderToggler, CNavItem, CButton } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'
import { useTranslation } from 'react-i18next'
import { cilAccountLogout } from '@coreui/icons'
import { logoutAction } from 'src/redux/actions/login'
import Modal from 'src/components/common/modal/Modal'

const AdminHeader = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sidebarShow = useSelector((state) => state.sidebarState.sidebarShow)
  const loginReducer = useSelector((state) => state.loginReducer)
  const adminName = loginReducer?.data?.result?.user?.name || t('header.admin_system')
  const [isShowModal, setShowModal] = useState(false)

  const handleChange = () => {
    dispatch({ type: 'set', sidebarShow: !sidebarShow })
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleLogout = () => {
    dispatch(logoutAction())
  }

  const _renderModal = () => {
    return (
      <Modal
        isTwoButton
        visible={isShowModal}
        onClose={handleCloseModal}
        onSubmit={handleLogout}
        content={t('modal.logout')}
        txtOK="OK"
        txtClose={t('modal.btn_cancel')}
        isSmall={true}
      />
    )
  }

  return (
    <CHeader position="sticky" className="header-admin">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={handleChange}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="ms-3 d-flex align-items-center">
          <CNavItem>
            <span className="header-admin-text">{adminName}</span>
            <CButton className="btn-logout mx-2" onClick={() => setShowModal(true)}>
              <CIcon icon={cilAccountLogout} size="lg" />
            </CButton>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
      {_renderModal()}
    </CHeader>
  )
}

export default AdminHeader
