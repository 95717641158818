const ACTION_LOGIN = 'ACTION_LOGIN'
const ACTION_LOGOUT = 'ACTION_LOGOUT'
const ACTION_LOGOUT_NOT_ALLOW = 'ACTION_LOGOUT_NOT_ALLOW'
const ACTION_LOGOUT_SUCCESS = 'ACTION_LOGOUT_SUCCESS'
const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS'
const ACTION_LOGIN_RESET = 'ACTION_LOGIN_RESET'
const ACTION_CLICK_MENU_FIRST = 'ACTION_CLICK_MENU_FIRST'

export {
  ACTION_LOGIN,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGIN_RESET,
  ACTION_LOGOUT,
  ACTION_LOGOUT_NOT_ALLOW,
  ACTION_LOGOUT_SUCCESS,
  ACTION_CLICK_MENU_FIRST,
}
