import {
  ACTION_LOGIN,
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGIN_RESET,
  ACTION_LOGOUT,
  ACTION_LOGOUT_SUCCESS,
  ACTION_LOGOUT_NOT_ALLOW,
  ACTION_CLICK_MENU_FIRST,
} from './ELoginActions'

export const loginAction = (payload) => {
  return {
    type: ACTION_LOGIN,
    payload,
  }
}

export const loginSuccessAction = (payload) => {
  return {
    type: ACTION_LOGIN_SUCCESS,
    payload,
  }
}

export const resetLoginAction = (payload) => {
  return {
    type: ACTION_LOGIN_RESET,
    payload,
  }
}

export const logoutAction = (payload) => {
  return {
    type: ACTION_LOGOUT,
    payload,
  }
}

export const logoutSuccessAction = (payload) => {
  return {
    type: ACTION_LOGOUT_SUCCESS,
    payload,
  }
}

export const logoutNotAllowAction = (payload) => {
  return {
    type: ACTION_LOGOUT_NOT_ALLOW,
    payload,
  }
}

export const setClickMenuAction = (payload) => {
  return {
    type: ACTION_CLICK_MENU_FIRST,
    payload,
  }
}
