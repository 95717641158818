import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilBullhorn, cilGroup, cilShieldAlt } from '@coreui/icons'
import { CNavItem, CNavGroup } from '@coreui/react'
import i18n from 'src/configs/i18n'
import router from '../router/strings'

const nav = [
  {
    component: CNavGroup,
    name: i18n.t('navigation.request'),
    icon: <CIcon icon={cilShieldAlt} customClassName="nav-icon" />,
    to: '/request',
    items: [
      {
        component: CNavItem,
        name: i18n.t('navigation.request_list'),
        to: router.REQUEST_LIST,
      },
      // {
      //   component: CNavItem,
      //   name: i18n.t('navigation.request_detail'),
      //   to: '/request/detail',
      // },
    ],
  },
  {
    component: CNavGroup,
    name: i18n.t('navigation.notification'),
    icon: <CIcon icon={cilBullhorn} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: i18n.t('navigation.notification_list'),
        to: router.NOTIFICATION_LIST,
      },
      {
        component: CNavItem,
        name: i18n.t('navigation.notification_register'),
        to: router.NOTIFICATION_REGISTER,
      },
    ],
  },
  {
    component: CNavGroup,
    name: i18n.t('navigation.admin'),
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: i18n.t('navigation.admin_list'),
        to: router.ADMIN_LIST,
      },
      {
        component: CNavItem,
        name: i18n.t('navigation.register_admin'),
        to: router.ADMIN_REGISTER,
      },
    ],
  },
]

export default nav
