/* eslint-disable react/prop-types */
import React from 'react'
import { CButton, CModal } from '@coreui/react'
import { useTranslation } from 'react-i18next'

const Modal = ({
  visible,
  onClose,
  content,
  txtOK,
  txtClose,
  isCustom = false,
  isSmall = false,
  component,
  onSubmit,
  isTwoButton = false,
}) => {
  const { t } = useTranslation()

  const _renderOneButton = () => {
    return (
      <div className="modal-noti-btn">
        <CButton
          className="font-size-13"
          onClick={() => {
            onClose && onClose()
            onSubmit && onSubmit()
          }}
        >
          {txtClose || t('email_input.close')}
        </CButton>
      </div>
    )
  }

  const _renderTwoButton = () => {
    return (
      <div className="modal-noti-btn">
        <CButton className="modal-noti-btn-cancel me-3 font-size-13" onClick={onClose}>
          {txtClose || t('email_input.close')}
        </CButton>
        <CButton
          className="modal-noti-btn-ok ms-3 font-size-13"
          onClick={() => {
            onClose && onClose()
            onSubmit && onSubmit()
          }}
        >
          {txtOK || t('email_input.close')}
        </CButton>
      </div>
    )
  }

  const _renderModal = () => {
    if (isCustom) {
      return <>{component}</>
    } else {
      return (
        <div className="modal-noti d-flex flex-column align-items-center">
          <div className="modal-noti-title">{content}</div>
          {isTwoButton ? _renderTwoButton() : _renderOneButton()}
        </div>
      )
    }
  }

  return (
    <CModal
      backdrop="static"
      size={isSmall ? `sm` : `lg`}
      visible={visible}
      onClose={onClose}
      alignment="center"
    >
      {_renderModal()}
    </CModal>
  )
}

export default Modal
