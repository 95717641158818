import i18n from '../configs/i18n'
import { Regex } from './Regex'

export const formatPhoneNumber = (input) => {
  if (input?.length === 10) {
    return input?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  } else {
    return input?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
  }
}

const checkNumber = (number) => {
  if (number < 10) {
    return '0' + number
  }
  return number
}

export const formatDateTimeURI = (date) => {
  const { days, months, years, hours, minutes, seconds } = getDateTimeFn(date)
  return `${years}${months}${days}${hours}${minutes}${seconds}`
}

export const formatDateTimeList = (date) => {
  const { days, months, years, hours, minutes } = getDateTimeFn(date)
  return `${years}/${months}/${days} ${hours}:${minutes}`
}

export const formatDateTime = (date) => {
  const { days, months, years, hours, minutes, seconds } = getDateTimeFn(date)
  return `${years}-${months}-${days} ${hours}:${minutes}:${seconds}`
}

export const formatDateTimeJP = (date) => {
  const { days, months, years, hours, minutes } = getDateTimeFn(date)
  return `${years}/${months}/${days} ${hours}:${minutes}`
}

const IMPORTANT_FLAG = {
  CHECK: '該当',
  NOT_CHECK: '該当しない',
}

const DISPLAY_FLAG = {
  CHECK: '表示',
  NOT_CHECK: '非表示',
}

const TYPE_DELIVERY_DATE = {
  IMMEDIATE_DELIVERY: '即時配信',
  DELIVERY_RESERVATION: '配信予約',
}

export const convertImportantFlag = (importantFlag) => {
  if (importantFlag) {
    return IMPORTANT_FLAG.CHECK
  } else {
    return IMPORTANT_FLAG.NOT_CHECK
  }
}

export const convertDisplayFlag = (displayFlag) => {
  if (displayFlag) {
    return DISPLAY_FLAG.CHECK
  } else {
    return DISPLAY_FLAG.NOT_CHECK
  }
}

export const convertTypeDeliveryDate = (type, date) => {
  if (type === 1) {
    return TYPE_DELIVERY_DATE.IMMEDIATE_DELIVERY
  } else {
    return formatDateTimeJP(date)
  }
}
function getDateTimeFn(date) {
  const formatDateSafari = (date) => {
    const dateFormat = date?.includes('-') ? date?.replace(Regex.regexDateSafari, '/') : date
    return new Date(dateFormat)
  }
  const newDate = date instanceof Date ? date : formatDateSafari(date)
  const days = checkNumber(newDate?.getDate())
  const months = checkNumber(newDate?.getMonth() + 1)
  const years = checkNumber(newDate?.getFullYear())
  const hours = checkNumber(newDate?.getHours())
  const minutes = checkNumber(newDate?.getMinutes())
  const seconds = checkNumber(newDate?.getSeconds())
  return { days, months, years, hours, minutes, seconds }
}

export const checkInvalidDate = (startDate, endDate) => {
  if (startDate !== '' && endDate !== '') {
    if (startDate?.getTime() > endDate?.getTime()) {
      return i18n.t('message.date_invalid')
    } else {
      return ''
    }
  }
  return ''
}

export const formatDateURIToString = (date) => {
  const result =
    date === ''
      ? ''
      : date.substr(6, 4) +
        '-' +
        date.substr(3, 2) +
        '-' +
        date.substr(0, 2) +
        ' ' +
        date.substr(11, 2) +
        ':' +
        date.substr(14, 2) +
        ':' +
        date.substr(17, 2)
  return result
}
