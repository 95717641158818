import React from 'react'
import AdminHeader from 'src/components/layout/admin/header/AdminHeader'
import AdminFooter from 'src/components/layout/admin/footer/AdminFooter'
import AdminSidebar from 'src/components/layout/admin/sidebar/AdminSidebar'
import { Outlet } from 'react-router-dom'
import ScrollToTop from 'src/components/common/scrollToTop/ScrollToTop'
import { ToastContainer } from 'react-toastify'

const AdminLayout = () => {
  return (
    <div>
      <AdminSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AdminHeader />
        <div className="body flex-grow-1 px-3">
          <div className="ms-2">
            <Outlet />
            <ScrollToTop />
          </div>
        </div>
        <AdminFooter />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  )
}

export default AdminLayout
