export const Endpoint = {
  LOGIN: 'admin/login',
  LOGOUT: 'admin/logout',
  REQUEST_LIST: 'admin/form-request/request-list',
  REQUEST_DETAIL: 'admin/form-request/request-detail/:id',
  REQUEST_UPDATE: 'admin/form-request/request-update/:id',
  FORGOT_PASSWORD: 'admin/forgot-password',
  CHECK_EMAIL_EXIST: 'admin/check-email-exist',
  RESET_PASSWORD: 'admin/reset-password',
  GET_ADMIN_LIST: 'admin/list',
  ADMIN_DETAIL: 'admin/:id',
  ADMIN_CREATE: 'admin/create',
  ADMIN_DELETE: 'admin/delete/:id',
  ADMIN_ROLE: 'admin/role/list',
  NOTIFICATIONS_LIST: 'admin/notifications/notification-list',
  NOTIFICATION_DETAIL: 'admin/notifications/detail/:id',
  NOTIFICATION_UPLOAD_IMAGE: 'admin/notifications/upload/image',
  NOTIFICATION_UPLOAD_ATTACH_FILE: 'admin/notifications/upload/attach-file',
  NOTIFICATION_UPLOAD_PDF_FILE: 'admin/notifications/upload/pdf',
  NOTIFICATION_CREATE: 'admin/notifications/notification-create',
  NOTIFICATION_DOWNLOAD_RESULT: 'admin/notifications/download-notification-result',
  NOTIFICATION_DOWNLOAD_FILE: 'admin/notifications/download-file',
  RESULT_SEND_MAIL: 'admin/notifications/result-send-mail',
  UPDATE_SEND_MAIL: 'admin/notifications/update-send-mail/:id',
  UPDATE_FLAG_NOTICE: 'admin/notifications/update-flag-notice/:id',
  NOTIFICATION_UPDATE: 'admin/notifications/notification-update/:id',
  NOTIFICATION_DELETE: 'admin/notifications/notification-delete/:id',
  GET_USER_DETAIL: 'admin/contracts/get-user-detail/:mcs_customer_code',
  OFFICE_LIST: 'admin/office/list',
  DOWNLOAD_USER_LIST: 'admin/contracts/download-user-list',
  UPLOAD_USER_LIST: 'admin/contracts/upload-user-list',
}
