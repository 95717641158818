import {
  DELETE_ADMIN,
  DELETE_ADMIN_RESET,
  DELETE_ADMIN_SUCCESS,
  GET_ADMIN_DETAIL,
  GET_ADMIN_DETAIL_RESET,
  GET_ADMIN_DETAIL_SUCCESS,
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_RESET,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_ROLE,
  GET_ADMIN_ROLE_RESET,
  GET_ADMIN_ROLE_SUCCESS,
  REGISTER_ADMIN,
  REGISTER_ADMIN_RESET,
  REGISTER_ADMIN_SUCCESS,
  UPDATE_ADMIN,
  UPDATE_ADMIN_RESET,
  UPDATE_ADMIN_SUCCESS,
} from './EAdminActions'

export const getAdminListAction = (payload) => {
  return {
    type: GET_ADMIN_LIST,
    payload,
  }
}

export const getAdminListActionSuccess = (payload) => {
  return {
    type: GET_ADMIN_LIST_SUCCESS,
    payload,
  }
}

export const resetAdminListAction = (payload) => {
  return {
    type: GET_ADMIN_LIST_RESET,
    payload,
  }
}

export const getAdminDetailAction = (payload) => {
  return {
    type: GET_ADMIN_DETAIL,
    payload,
  }
}

export const getAdminDetailActionSuccess = (payload) => {
  return {
    type: GET_ADMIN_DETAIL_SUCCESS,
    payload,
  }
}

export const resetAdminDetailAction = (payload) => {
  return {
    type: GET_ADMIN_DETAIL_RESET,
    payload,
  }
}

export const registerAdminAction = (payload) => {
  return {
    type: REGISTER_ADMIN,
    payload,
  }
}

export const registerAdminActionSuccess = (payload) => {
  return {
    type: REGISTER_ADMIN_SUCCESS,
    payload,
  }
}

export const resetRegisterAdminActionSuccess = (payload) => {
  return {
    type: REGISTER_ADMIN_RESET,
    payload,
  }
}

export const deleteAdminAction = (payload) => {
  return {
    type: DELETE_ADMIN,
    payload,
  }
}

export const deleteAdminActionSuccess = (payload) => {
  return {
    type: DELETE_ADMIN_SUCCESS,
    payload,
  }
}

export const resetDeleteAdminActionSuccess = (payload) => {
  return {
    type: DELETE_ADMIN_RESET,
    payload,
  }
}

export const updateAdminAction = (payload) => {
  return {
    type: UPDATE_ADMIN,
    payload,
  }
}

export const updateAdminActionSuccess = (payload) => {
  return {
    type: UPDATE_ADMIN_SUCCESS,
    payload,
  }
}

export const resetUpdateAdminActionSuccess = (payload) => {
  return {
    type: UPDATE_ADMIN_RESET,
    payload,
  }
}

export const getAdminRoleAction = (payload) => {
  return {
    type: GET_ADMIN_ROLE,
    payload,
  }
}

export const getAdminRoleActionSuccess = (payload) => {
  return {
    type: GET_ADMIN_ROLE_SUCCESS,
    payload,
  }
}

export const resetGetAdminRoleActionSuccess = (payload) => {
  return {
    type: GET_ADMIN_ROLE_RESET,
    payload,
  }
}
