import React from 'react'
import i18n from 'src/configs/i18n'
import router from './strings'

const AdminList = React.lazy(() => import('../views/pages/admin/AdminList'))
const AdminRegister = React.lazy(() => import('../views/pages/admin/AdminRegister'))
const RequestList = React.lazy(() => import('../views/pages/request/RequestList'))
const DetailRequest = React.lazy(() => import('../views/pages/request/DetailRequest'))
const NotificationList = React.lazy(() => import('../views/pages/notification/NotificationList'))
const NotificationRegister = React.lazy(() =>
  import('../views/pages/notification/NotificationRegister'),
)
const ConfirmNotificationRegister = React.lazy(() =>
  import('../views/pages/notification/ConfirmNotificationRegister'),
)
const DetailNotification = React.lazy(() =>
  import('../views/pages/notification/DetailNotification'),
)
const Login = React.lazy(() => import('../views/pages/login/Login'))
const ForgotPassword = React.lazy(() => import('../views/pages/forgot_password/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../views/pages/reset_password/ResetPassword'))
const Register = React.lazy(() => import('../views/pages/register/Register'))
const Page404 = React.lazy(() => import('../views/pages/404/Page404'))

const routes = [
  {
    path: router.REQUEST_LIST,
    name: i18n.t('navigation.request'),
    element: RequestList,
    auth: false,
  },
  {
    path: router.REQUEST_DETAIL,
    name: i18n.t('request_detail.header'),
    element: DetailRequest,
    auth: false,
  },
  {
    path: router.NOTIFICATION_LIST,
    name: i18n.t('navigation.notification_list'),
    element: NotificationList,
    auth: false,
  },
  {
    path: router.NOTIFICATION_CONFIRM_REGISTER,
    name: i18n.t('navigation.confirm_notification_register'),
    element: ConfirmNotificationRegister,
    auth: false,
  },
  {
    path: router.NOTIFICATION_REGISTER,
    name: i18n.t('navigation.notification_register'),
    element: NotificationRegister,
    auth: false,
  },
  {
    path: router.NOTIFICATION_DETAIL,
    name: i18n.t('navigation.detail_notification'),
    element: DetailNotification,
    auth: false,
  },
  {
    path: router.NOTIFICATION_EDIT,
    name: i18n.t('navigation.detail_notification'),
    element: NotificationRegister,
    auth: false,
  },
  {
    path: router.ADMIN_LIST,
    name: i18n.t('navigation.admin_list'),
    element: AdminList,
    auth: false,
  },
  {
    path: router.ADMIN_REGISTER,
    name: i18n.t('navigation.register_admin'),
    element: AdminRegister,
    auth: false,
  },
  {
    path: router.ADMIN_DETAIL,
    name: i18n.t('navigation.detail_admin'),
    element: AdminRegister,
    auth: false,
  },
  // { path: '/banner', name: 'BannerTest', element: BannerTest, auth: false },
  { path: router.LOGIN, name: 'Login', element: Login, auth: true },
  { path: router.FORGOT_PASSWORD, name: 'ForgotPassword', element: ForgotPassword, auth: true },
  { path: router.RESET_PASSWORD, name: 'ResetPassword', element: ResetPassword, auth: true },
  { path: router.REGISTER, name: 'Register', element: Register, auth: true },
  { path: router.PAGE_404, name: '404', element: Page404, auth: true },
]

export default routes
