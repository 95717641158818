import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import routes from './routes'
import AuthLayout from 'src/views/layout/AuthLayout'

const AuthRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {routes.map((route, idx) => {
          if (route.auth) {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          }
          return null
        })}
        <Route path="*" element={<Navigate to="login" replace />} />
      </Route>
    </Routes>
  )
}

export default AuthRouter
