import React, { useEffect, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'
import { PERMISSION_ADMIN } from 'src/utils/strings'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setClickMenuAction } from 'src/redux/actions/login'
import { getAdminRoleAction } from 'src/redux/actions/admin'

export const AdminSidebarNav = ({ items, moduleAccess }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const refs = useRef([])
  const user = useSelector((state) => state.loginReducer)

  //Handle auto click sidebar with nav group item first
  useEffect(() => {
    if (refs?.current?.length > 0 && user?.isFirstClickMenu) {
      dispatch(getAdminRoleAction())
      dispatch(setClickMenuAction())
      refs?.current[0]?.children[0]?.click()
    }
    // eslint-disable-next-line
  }, [refs])

  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, className, ...rest } = item
    const Component = component
    //Not permission view notification
    if (
      item?.name === t('navigation.notification_list') &&
      moduleAccess &&
      !moduleAccess[PERMISSION_ADMIN.VIEW_NOTIFICATION]?.is_accessile
    ) {
      return null
    }
    //Not permission register notification
    if (
      item?.name === t('navigation.notification_register') &&
      moduleAccess &&
      !moduleAccess[PERMISSION_ADMIN.CUD_NOTIFICATION]?.is_accessile
    ) {
      return null
    }
    //Not permission view admin
    if (
      item?.name === t('navigation.admin_list') &&
      moduleAccess &&
      !moduleAccess[PERMISSION_ADMIN.VIEW_ADMIN]?.is_accessile
    ) {
      return null
    }
    //Not permission register admin
    if (
      item?.name === t('navigation.register_admin') &&
      moduleAccess &&
      !moduleAccess[PERMISSION_ADMIN.CUD_ADMIN]?.is_accessile
    ) {
      return null
    }
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        className="font-size-13"
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    //Not permission view request
    if (
      item?.name === t('navigation.request') &&
      moduleAccess &&
      !moduleAccess[PERMISSION_ADMIN.CUD_REQUEST_FROM]?.is_accessile &&
      !moduleAccess[PERMISSION_ADMIN.VIEW_REQUEST_FROM]?.is_accessile
    ) {
      return null
    }
    //Not permission view notification
    if (
      item?.name === t('navigation.notification') &&
      moduleAccess &&
      !moduleAccess[PERMISSION_ADMIN.CUD_NOTIFICATION]?.is_accessile &&
      !moduleAccess[PERMISSION_ADMIN.VIEW_NOTIFICATION]?.is_accessile
    ) {
      return null
    }
    //Not permission view admin
    if (
      item?.name === t('navigation.admin') &&
      moduleAccess &&
      !moduleAccess[PERMISSION_ADMIN.CUD_ADMIN]?.is_accessile &&
      !moduleAccess[PERMISSION_ADMIN.VIEW_ADMIN]?.is_accessile
    ) {
      return null
    }
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        ref={(el) => (refs.current[index] = el)}
        className="font-size-13"
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

AdminSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  moduleAccess: PropTypes.array,
}
