import i18n from 'src/configs/i18n'

const BASE_URL = process.env.REACT_APP_API_URL
const ROLES = [
  {
    id: 1,
    name: i18n.t('role.system_full'),
    permission: 1,
  },
  {
    id: 2,
    name: i18n.t('role.system_view'),
    permission: 2,
  },
  {
    id: 3,
    name: i18n.t('role.hq_full'),
    permission: 3,
  },
  {
    id: 4,
    name: i18n.t('role.hq_view'),
    permission: 4,
  },
  {
    id: 5,
    name: i18n.t('role.shop_full'),
    permission: 5,
  },
  {
    id: 6,
    name: i18n.t('role.shop_view'),
    permission: 6,
  },
]

const STRING_MULTI_SELECT = {
  allItemsAreSelected: i18n.t('message.select_all'),
  selectAll: i18n.t('message.select_all'),
  selectAllFiltered: i18n.t('message.select_all'),
  search: i18n.t('request_list.search'),
  noOptions: i18n.t('message.no_data'),
}

export { BASE_URL, ROLES, STRING_MULTI_SELECT }
