export const ResponseError = {
  // -- SYSTEM errors
  ENV_NOT_SET_ERROR: { message: 'ENV_NOT_SET_ERROR' },
  SERVER_SHUTTING_DOWN: { message: 'SERVER_SHUTTING_DOWN' },
  LISTEN_ERROR: { message: 'LISTEN_ERROR' },
  INVALID_TOKEN: { message: 'INVALID_TOKEN' },
  TOKEN_EXPIRED_ERROR: { message: 'TOKEN_EXPIRED_ERROR' },

  // -- API COMMON errors
  SYSTEM_ERROR_500: { code: 500, message: 'SYSTEM_ERROR_500' },
  SYSTEM_ERROR_510: { code: 510, message: 'SYSTEM_ERROR_510' },
  INTERNAL_SERVER_ERROR: { code: 8003, message: 'INTERNAL_SERVER_ERROR' },
  NOT_FOUND_DATA_ERROR: { code: 8001, message: 'NOT_FOUND_DATA_ERROR' },
  NOT_ALLOW_PERMISSION: { code: 8004, message: 'NOT_ALLOW_PERMISSION' },
  NOT_FOUND_API_URL_ERROR: { code: 8005, message: 'NOT_FOUND_API_URL_ERROR' },
  NO_AUTHENTICATED_ERROR: { code: 5100, message: 'NO_AUTHENTICATED_ERROR' },
  NOT_ALLOW: { code: 403, message: 'NOT_ALLOW' },
  INVALID_PAGE: { code: 8007, message: 'INVALID_PAGE' },
  INVALID_EMAIL: { code: 8002, message: 'INVALID_EMAIL' },

  LOGIN_ERROR: { code: 202, message: 'LOGIN_ERROR' },
  TOKEN_INVALID: { code: 40043, message: 'TOKEN_INVALID' },
  TOKEN_EXPIRED: { code: 40044, message: 'TOKEN_EXPIRED' },
}
