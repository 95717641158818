const GET_REQUEST_LIST = 'GET_REQUEST_LIST'
const GET_REQUEST_LIST_SUCCESS = 'GET_REQUEST_LIST_SUCCESS'
const GET_REQUEST_LIST_RESET = 'GET_REQUEST_LIST_RESET'
const GET_REQUEST_DETAIL = 'GET_REQUEST_DETAIL'
const GET_REQUEST_DETAIL_SUCCESS = 'GET_REQUEST_DETAIL_SUCCESS'
const GET_REQUEST_DETAIL_RESET = 'GET_REQUEST_DETAIL_RESET'
const UPDATE_REQUEST_DETAIL = 'UPDATE_REQUEST_DETAIL'
const UPDATE_REQUEST_DETAIL_SUCCESS = 'UPDATE_REQUEST_DETAIL_SUCCESS'
const UPDATE_REQUEST_DETAIL_RESET = 'UPDATE_REQUEST_DETAIL_RESET'

export {
  GET_REQUEST_LIST,
  GET_REQUEST_LIST_SUCCESS,
  GET_REQUEST_LIST_RESET,
  GET_REQUEST_DETAIL,
  GET_REQUEST_DETAIL_SUCCESS,
  GET_REQUEST_DETAIL_RESET,
  UPDATE_REQUEST_DETAIL,
  UPDATE_REQUEST_DETAIL_SUCCESS,
  UPDATE_REQUEST_DETAIL_RESET,
}
