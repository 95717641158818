import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import {
  GET_REQUEST_DETAIL,
  GET_REQUEST_LIST,
  UPDATE_REQUEST_DETAIL,
} from 'src/redux/actions/request/ERequestActions'
import {
  getRequestDetailActionSuccess,
  getRequestListActionSuccess,
  updateRequestDetailActionSuccess,
} from 'src/redux/actions/request'
import { Endpoint } from 'src/redux/sagas/Endpoint'
import { generatePath } from 'react-router-dom'
import { ResponseStatus } from 'src/utils/ResponseStatus'

function* submitGetRequestListSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.REQUEST_LIST, { params: action?.payload })
    }
    const response = yield call(configRequest)
    yield put(getRequestListActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getRequestListActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitGetRequestDetailSaga(action) {
  try {
    const url = generatePath(Endpoint.REQUEST_DETAIL, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.get(url)
    }
    const response = yield call(configRequest)
    yield put(getRequestDetailActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getRequestDetailActionSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitUpdateRequestDetailSaga(action) {
  try {
    const url = generatePath(Endpoint.REQUEST_UPDATE, {
      id: action?.payload?.id,
    })
    const params = {
      response_status: action?.payload?.response_status,
      note: action?.payload?.note,
    }
    const configRequest = () => {
      return instance.put(url, params)
    }
    const response = yield call(configRequest)
    yield put(updateRequestDetailActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      updateRequestDetailActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

export default function* requestSaga() {
  yield all([takeLatest(GET_REQUEST_LIST, submitGetRequestListSaga)])
  yield all([takeLatest(GET_REQUEST_DETAIL, submitGetRequestDetailSaga)])
  yield all([takeLatest(UPDATE_REQUEST_DETAIL, submitUpdateRequestDetailSaga)])
}
