import { GET_OFFICE_LIST, GET_OFFICE_LIST_SUCCESS } from './officeActions.js'

export const getOfficeListAction = (payload) => {
  return {
    type: GET_OFFICE_LIST,
    payload,
  }
}

export const getOfficeListActionSuccess = (payload) => {
  return {
    type: GET_OFFICE_LIST_SUCCESS,
    payload,
  }
}
