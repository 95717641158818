import {
  CHECK_EMAIL_ACTION,
  CHECK_EMAIL_SUCCESS_ACTION,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_SUCCESS_ACTION,
  RESET_ACTION,
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
} from './EForgotPasswordActions'

export const checkEmailAction = (payload) => {
  return {
    type: CHECK_EMAIL_ACTION,
    payload,
  }
}

export const checkEmailSuccessAction = (payload) => {
  return {
    type: CHECK_EMAIL_SUCCESS_ACTION,
    payload,
  }
}
export const resetPasswordAction = (payload) => {
  return {
    type: RESET_PASSWORD_ACTION,
    payload,
  }
}

export const resetPasswordSuccessAction = (payload) => {
  return {
    type: RESET_PASSWORD_SUCCESS_ACTION,
    payload,
  }
}

export const resetValueAction = (payload) => {
  return {
    type: RESET_ACTION,
    payload,
  }
}

export const checkEmailTokenResetPasswordAction = (payload) => {
  return {
    type: ACTION_CHECK_TOKEN_RESET_PASSWORD,
    payload,
  }
}

export const checkEmailTokenResetPasswordSuccessAction = (payload) => {
  return {
    type: ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
    payload,
  }
}
