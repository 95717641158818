// Multi language japanese
const ja = {
  navigation: {
    notification: '通知',
    notification_register: '通知登録',
    notification_list: '通知一覧',
    request: '問合せ',
    request_list: '問合せ一覧',
    request_detail: '問合せ詳細',
    register_admin: '管理者登録',
    admin: '管理者',
    admin_list: '管理者一覧',
    confirm_notification_register: '通知登録確認',
    detail_notification: '通知詳細',
    detail_admin: '管理者詳細',
    edit_notification: '通知編集',
  },
  header: {
    admin_system: 'システム管理者',
  },
  footer: 'Copyright © 2022 MITSUWA SANGYO CO., Ltd. All Rights Reserved.',
  login: {
    title: '管理者ログイン',
    branch_code: '支店コード',
    admin_id: '管理者ID',
    password: 'パスワード',
    confirm_password: 'パスワード（確認）',
    placeholder_admin_id: 'メールアドレス入力',
    placeholder_password: 'パスワード入力',
    reset_password: 'パスワードリセットはこちら',
    login: 'ログイン',
    login_error: 'メールアドレスまたはパスワードが正しくありません。',
  },
  email_input: {
    title: 'パスワードリセットするための案内メールを送付いたします。',
    description: 'ご登録のメールアドレスを入力してください',
    email: 'メールアドレス',
    placeholder_email: 'メールアドレス入力',
    send: '送信',
    modal_title:
      'ご登録メールアドレスにメールを送信しました。\nメールにあるURLの有効期限は2時間となりますので早めにご確認ください。',
    close: '閉じる',
    agency: '支店コード',
  },
  set_password: {
    title: 'パスワード設定',
    button: '設定',
  },
  reset_password: {
    title: 'パスワード再設定',
    new_password: '新パスワード',
    confirm_new_password: '新パスワード（確認）',
    message: 'パスワード再設定しました。',
    message_set_pass: 'パスワード登録しました',
    close: '閉じる',
    reset: '再設定',
  },
  message: {
    upload: 'または',
    required: 'を入力して下さい。',
    invalid: 'は正しい形式ではありません。',
    incorrect: 'を正しく入力してください。',
    min_character_invalid: '文字以上で入力してください。',
    password_not_match: '同じパスワードが入力されていません。',
    no_data: 'データがありません。',
    select_all: '全て',
    date_invalid: '開始日時は終了日時より早い日時で入力してください。',
  },
  email: 'メールアドレス',
  password: 'パスワード',
  admin_name: '管理者名',
  request_list: {
    header: '問合せ一覧',
    condition_search: '検索条件',
    company: '事業所',
    customer_name: '顧客名',
    customer_code: '顧客コード',
    placeholder_customer_name: '顧客名または顧客コード入力',
    time: '受付期間',
    phone_number: '電話番号',
    status: 'ステータス',
    not_compatible: '未対応',
    done: '対応済',
    during_correspondence: '対応中',
    clear: 'クリア',
    search: '検索',
    table: {
      date_time: '受付日時',
      customer_name: '顧客名',
      customer_id: '顧客コード',
      phone_number: '電話番号',
      kinds: '問合せ種別',
      company: '事業所',
      status: 'ステータス',
      detail: '詳細',
    },
  },
  scrollToTop: 'ＴＯＰへ戻る',
  request_detail: {
    header: '問合せ詳細',
    infor_request: '問合せ情報',
    status: 'ステータス',
    request: '問合せ種別',
    request_detail: '問合せ詳細',
    customer_name: '顧客名',
    phone_number: '電話番号',
    address: '住所',
    system_updater: 'システム更新者',
    forwarding_email: '転送先メールアドレス',
    remark: '備考',
    not_compatible: '未対応',
    done: '対応済',
    during_correspondence: '対応中',
    reception_date_time: '受付日時',
    customer_id: '顧客コード',
    email: 'メールアドレス',
    note: '備考は顧客画面に表示されません。',
    cancel: 'キャンセル',
    keep: '保存',
    shop_name: '事業所',
  },
  notification_list: {
    header: '通知一覧',
    condition_search: '検索条件',
    customer_name: '顧客名 ',
    customer_code: '顧客コード',
    search_notification: 'お知らせ検索 ',
    placeholder_customer_name: '顧客名または顧客コード入力',
    placeholder_search_notification: 'キーワードを入力してください',
    delivery_date: '配信日時',
    status: 'ステータス',
    delivery_type: '配信種別',
    business_office: '事業所',
    draft: '下書き',
    delivery_reservation: '配信予約',
    delivery: '配信済み',
    delivery__designated: '指定配信',
    list_distribution: 'リスト配信',
    important_flag: '重要フラグ',
    applicable: '該当',
    not_applicable: '該当しない',
    display_flag: '表示フラグ',
    register_notification: '通知登録',
    display: '表示',
    not_display: '非表示',
    table: {
      important_flag: '重要フラグ',
      display_flag: '表示フラグ',
      delivery_date: '配信日時',
      title: 'タイトル',
      text: '本文',
      status: 'ステータス',
      business_office: '事業所',
      delivery_type: '配信種別',
      detail: '詳細',
    },
    about_status: '※ステータスについて：',
    about_important_flags: '※重要フラグについて：',
    about_draft: '顧客画面に公開されない下書きの状態',
    about_undelivered: '顧客画面に公開されるが、配信日時になっていない未配信の通知',
    about_notification: '顧客画面に配信・公開された通知',
    about_important_flags_notification:
      '重要フラグにチェックをしたお知らせは、顧客画面の上位に表示されます',
  },
  notification_register: {
    header: '通知登録',
    recipient_information: '通知先',
    download: '顧客リストダウンロ ード',
    delivery_type: '配信種別',
    delivery__designated: '指定',
    delivery_status: '配信ステータス',
    list_distribution: 'リスト',
    receiver: '通知先',
    placeholder_receiver: '顧客コード入力し、追加ボタンを押してください',
    add_to_box: '追加',
    notification_content: '通知内容',
    notification_content_upload: '本文アップロード',
    selection: '追加',
    notification_text: '本文入力',
    uploading: '処理中',
    pdf_upload_file: 'PDFファイルアップロード',
    image_pc: '画像（PC用）',
    image_sp: '画像（スマホ用）',
    choose_file: 'ファイルを選択',
    recommend_size_pc:
      '※推薦サイズ　横：1200px　縦：630px。アップロード可能なファイル形式：JPG,JPEG,PNG',
    recommend_size_sp:
      '※推薦サイズ　横：300px　縦：188px。アップロード可能なファイル形式：JPG,JPEG,PNG',
    recommend_important_flag: '※メール通知を不可にしているユーザーにも通知されます',
    important_flag: '重要フラグ',
    display_flag: '表示フラグ',
    delivery_date: '通知日時',
    delivery_immediate: '即時通知',
    delivery_reservation: '予約通知',
    register_person: '登録者',
    notice_sending_result: '通知送付結果',
    capture_result: '取込結果',
    changer: '更新者',
    business_office: '事業所',
    register_completion_email: '登録完了メール送付先',
    remark: '備考',
    text_remark: '※備考は顧客画面に表示されません',
    cancel: 'キャンセル',
    preview: 'プレビュー',
    save_as_draft: '下書きで保存',
    registration_confirmation: '登録確認',
    import_list: 'CSVインポート',
    download_list: '顧客リストダウンロ ード',
    download_result: '結果ダウンロード',
    notification_title: 'タイトル',
    btn_register: '登録',
    btn_delete: '削除',
    btn_edit: '編集',
    customer_code_not_exist: 'データがありません。',
    customer_code_not_register: 'アプリのユーザ登録が完了していません。',
    customer_code_duplicate: '既に通知先リストに登録されています。',
    customer_code_max_length: '通知先リストに指定できるのは最大20レコードまでです。',
  },
  confirm_notification_register: {
    header: '通知登録',
  },
  detail_notification: {
    header: '通知詳細',
    result: {
      success: '成功：{{success}}件',
      error: 'エラー：{{error}}件',
      warning: '警告：{{warning}}件',
      total: '（合計{{total}}件）',
    },
  },
  admin_page: {
    admin_name: '管理者名',
    admin_type: '管理者種別',
    admin_system: 'システム管理者',
    head_office_manager: '本社管理者',
    sale_office_manager: '営業所管理者',
    authority: '権限',
    read_only: '閲覧のみ',
    all_right: '全ての権限',
    all_privileges: 'すべての権限',
    btn_register: '管理者登録',
    affiliation: '事業所',
    email: 'メールアドレス',
    table: {
      email: 'メールアドレス',
      detail: '詳細',
      kinds: '種別',
      belongs: '事業所',
      authority: '権限',
      admin_name: '管理者名',
    },
    btn_close: 'キャンセル',
    button_register: '登録',
    btn_delete: '削除',
    button_edit: '保存',
  },
  modal: {
    logout: 'ログアウトしてもよろしいですか。',
    txt_500: 'エラーが発生しました。もう一度お試しください。',
    confirm_logout: 'の管理者アカウントにログインされています。ログアウトしますか。',
    confirm_result_email: '通知登録しました。',
    confirm_result_email_1:
      '通知リストファイルを処理しておりますが、アップロード結果が出るまで時間がかかります。',
    confirm_result_email_2:
      '続けてお待ちいただくか、アップロード結果をメールで受け取っていただくかお選びください。',
    result_notification_email: '通知先リストアップロード結果',
    txt_success: '成功：',
    txt_error: 'エラー：',
    txt_warning: '警告：',
    txt_total: '合計：',
    txt_pieces: '件',
    confirm_delete_notification: '削除してもよろしいですか。',
    delete_notification_success: '削除しました。',
    confirm_edit_priority_flag: '重要フラグを切り替えてよろしいですか。',
    confirm_edit_show_flag: '表示フラグを切り替えてよろしいですか。',
    confirm_edit_notification: '編集してもよろしいですか。',
    confirm_cancel: 'キャンセルしてもよろしいですか。',
    confirm_save_draft: '下書きで保存してもよろしいですか。',
    confirm_cancel_edit: '編集をキャンセルしてよろしいですか。',
    confirm_update_my_role:
      'ご自分のアカウント権限を変更すると、\n一旦システムからログアウトし、再度ログインする必要があります。\n権限を変更してもよろしいでしょうか。',
    create: '登録しました。',
    update: '保存しました。',
    btn_receive_results_by_email: 'メールで結果を受け取る',
    btn_continue: '続ける',
    btn_cancel: 'キャンセル',
    btn_close: '閉じる',
    btn_switch: '切替',
    btn_ok: 'OK',
    btn_logout: 'ログアウト',
    btn_login: 'ログイン',
  },
  role: {
    hq_view: '本社管理者（閲覧）',
    hq_full: '本社管理者（全権限）',
    shop_view: '営業所管理者（閲覧）',
    shop_full: '営業所管理者（全権限）',
    system_view: 'システム管理者（閲覧）',
    system_full: 'システム管理者（全権限）',
  },
  error: {
    file_error_max_length: '通知内容は3000文字数以内に修正してください。',
    url_expired: 'URLの有効期限が切れました。もう一度やり直してください。',
    not_exist: '指定されたページは存在しません。',
    go_login: 'ログイン画面へ',
  },
}

export default ja
