import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import { ACTION_LOGIN, ACTION_LOGOUT } from 'src/redux/actions/login/ELoginActions'
import { loginSuccessAction, logoutSuccessAction } from 'src/redux/actions/login'
import { Endpoint } from 'src/redux/sagas/Endpoint'
import { Auth } from 'src/helpers/Auth'

function* submitLoginSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.LOGIN, action?.payload)
    }
    const response = yield call(configRequest)
    if (response?.data) {
      Auth.setToken(response?.data?.result?.token)
    }
    yield put(loginSuccessAction(response?.data))
  } catch (error) {
    let msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      loginSuccessAction({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* logoutSaga() {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.LOGOUT)
    }
    const response = yield call(configRequest)
    Auth.removeToken()
    yield put(logoutSuccessAction(response))
  } catch (error) {
    Auth.removeToken()
    yield put(logoutSuccessAction({ result: true }))
  }
}

export default function* loginSaga() {
  yield all([takeLatest(ACTION_LOGIN, submitLoginSaga)])
  yield all([takeLatest(ACTION_LOGOUT, logoutSaga)])
}
