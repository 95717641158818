import {
  GET_REQUEST_LIST,
  GET_REQUEST_LIST_SUCCESS,
  GET_REQUEST_DETAIL,
  GET_REQUEST_DETAIL_SUCCESS,
  UPDATE_REQUEST_DETAIL,
  UPDATE_REQUEST_DETAIL_SUCCESS,
  UPDATE_REQUEST_DETAIL_RESET,
  GET_REQUEST_DETAIL_RESET,
  GET_REQUEST_LIST_RESET,
} from './ERequestActions'

export const getRequestListAction = (payload) => {
  return {
    type: GET_REQUEST_LIST,
    payload,
  }
}

export const getRequestListActionSuccess = (payload) => {
  return {
    type: GET_REQUEST_LIST_SUCCESS,
    payload,
  }
}

export const resetRequestListAction = (payload) => {
  return {
    type: GET_REQUEST_LIST_RESET,
    payload,
  }
}

export const getRequestDetailAction = (payload) => {
  return {
    type: GET_REQUEST_DETAIL,
    payload,
  }
}

export const getRequestDetailActionSuccess = (payload) => {
  return {
    type: GET_REQUEST_DETAIL_SUCCESS,
    payload,
  }
}

export const resetRequestDetailAction = (payload) => {
  return {
    type: GET_REQUEST_DETAIL_RESET,
    payload,
  }
}

export const updateRequestDetailAction = (payload) => {
  return {
    type: UPDATE_REQUEST_DETAIL,
    payload,
  }
}

export const updateRequestDetailActionSuccess = (payload) => {
  return {
    type: UPDATE_REQUEST_DETAIL_SUCCESS,
    payload,
  }
}

export const resetUpdateRequestDetailAction = (payload) => {
  return {
    type: UPDATE_REQUEST_DETAIL_RESET,
    payload,
  }
}
