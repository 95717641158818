import { put, takeLatest, all, call } from 'redux-saga/effects'
import { getOfficeListActionSuccess } from 'src/redux/actions/office'
import { GET_OFFICE_LIST } from 'src/redux/actions/office/officeActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'

function* getOfficeListSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.OFFICE_LIST, { params: action?.payload })
    }
    const response = yield call(configRequest)
    yield put(getOfficeListActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getOfficeListActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

export default function* officeSaga() {
  yield all([takeLatest(GET_OFFICE_LIST, getOfficeListSaga)])
}
