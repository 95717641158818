const router = {
  HOME: '/',
  REQUEST_LIST: '/request/list',
  REQUEST_DETAIL: '/request/detail/:id',
  NOTIFICATION_LIST: '/notification/list',
  NOTIFICATION_CONFIRM_REGISTER: '/notification/register/confirm',
  NOTIFICATION_REGISTER: '/notification/register',
  NOTIFICATION_DETAIL: '/notification/detail/:id',
  NOTIFICATION_EDIT: '/notification/edit/:id',
  ADMIN_LIST: '/admin/list',
  ADMIN_REGISTER: '/admin/register',
  ADMIN_DETAIL: '/admin/detail/:id',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  REGISTER: '/register',
  PAGE_404: '/404',
}

export default router
