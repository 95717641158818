import {
  CHECK_EMAIL_ACTION,
  CHECK_EMAIL_SUCCESS_ACTION,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_SUCCESS_ACTION,
  RESET_ACTION,
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS,
} from 'src/redux/actions/forgot-password/EForgotPasswordActions'

const INITIAL_STATE = {
  dataCheckEmail: {},
  dataResetPassword: {},
  checkTokenChangePassword: {},
  loading: false,
}

const forgotPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_EMAIL_ACTION:
      return { ...state, loading: true, dataCheckEmail: {} }

    case CHECK_EMAIL_SUCCESS_ACTION:
      return {
        ...state,
        dataCheckEmail: action.payload,
        loading: false,
      }
    case RESET_PASSWORD_ACTION:
      return { ...state, loading: true, dataResetPassword: {} }

    case RESET_PASSWORD_SUCCESS_ACTION:
      return {
        ...state,
        dataResetPassword: action.payload,
        loading: false,
      }
    case ACTION_CHECK_TOKEN_RESET_PASSWORD:
      return {
        ...state,
      }
    case ACTION_CHECK_TOKEN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        checkTokenChangePassword: action.payload,
      }
    case RESET_ACTION:
      return {
        ...state,
        dataResetPassword: {},
        dataCheckEmail: {},
        checkTokenChangePassword: {},
        loading: false,
      }
    default:
      return state
  }
}

export default forgotPasswordReducer
