const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST'
const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS'
const GET_NOTIFICATION_LIST_RESET = 'GET_NOTIFICATION_LIST_RESET'
const REGISTER_NOTIFICATION = 'REGISTER_NOTIFICATION'
const REGISTER_NOTIFICATION_SUCCESS = 'REGISTER_NOTIFICATION_SUCCESS'
const REGISTER_NOTIFICATION_RESET = 'REGISTER_NOTIFICATION_RESET'
const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
const UPDATE_NOTIFICATION_RESET = 'UPDATE_NOTIFICATION_RESET'
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
const DELETE_NOTIFICATION_RESET = 'DELETE_NOTIFICATION_RESET'
const GET_NOTIFICATION_DETAIL = 'GET_NOTIFICATION_DETAIL'
const GET_NOTIFICATION_DETAIL_SUCCESS = 'GET_NOTIFICATION_DETAIL_SUCCESS'
const NOTIFICATION_DETAIL_RESET = 'NOTIFICATION_DETAIL_RESET'
const DOWNLOAD_USER_LIST = 'DOWNLOAD_USER_LIST'
const DOWNLOAD_USER_LIST_SUCCESS = 'DOWNLOAD_USER_LIST_SUCCESS'
const DOWNLOAD_USER_LIST_RESET = 'DOWNLOAD_USER_LIST_RESET'
const UPLOAD_USER_LIST = 'UPLOAD_USER_LIST'
const UPLOAD_USER_LIST_SUCCESS = 'UPLOAD_USER_LIST_SUCCESS'
const UPLOAD_USER_LIST_RESET = 'UPLOAD_USER_LIST_RESET'
const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
const UPLOAD_IMAGE_RESET = 'UPLOAD_IMAGE_RESET'
const UPLOAD_ATTACH_FILE = 'UPLOAD_ATTACH_FILE'
const UPLOAD_ATTACH_FILE_SUCCESS = 'UPLOAD_ATTACH_FILE_SUCCESS'
const UPLOAD_ATTACH_FILE_RESET = 'UPLOAD_ATTACH_FILE_RESET'
const UPLOAD_PDF_FILE = 'UPLOAD_PDF_FILE'
const UPLOAD_PDF_FILE_SUCCESS = 'UPLOAD_PDF_FILE_SUCCESS'
const UPLOAD_PDF_FILE_RESET = 'UPLOAD_PDF_FILE_RESET'
const RESULT_SEND_MAIL = 'RESULT_SEND_MAIL'
const RESULT_SEND_MAIL_SUCCESS = 'RESULT_SEND_MAIL_SUCCESS'
const RESULT_SEND_MAIL_RESET = 'RESULT_SEND_MAIL_RESET'
const DOWNLOAD_NOTIFICATION_RESULT = 'DOWNLOAD_NOTIFICATION_RESULT'
const DOWNLOAD_NOTIFICATION_RESULT_SUCCESS = 'DOWNLOAD_NOTIFICATION_RESULT_SUCCESS'
const DOWNLOAD_NOTIFICATION_RESULT_RESET = 'DOWNLOAD_NOTIFICATION_RESULT_RESET'
const UPDATE_SEND_MAIL = 'UPDATE_SEND_MAIL'
const UPDATE_SEND_MAIL_SUCCESS = 'UPDATE_SEND_MAIL_SUCCESS'
const UPDATE_SEND_MAIL_RESET = 'UPDATE_SEND_MAIL_RESET'
const EDIT_STATUS_LIST = 'EDIT_STATUS_LIST'
const EDIT_STATUS_LIST_SUCCESS = 'EDIT_STATUS_LIST_SUCCESS'
const EDIT_STATUS_LIST_RESET = 'EDIT_STATUS_LIST_RESET'
const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
const DOWNLOAD_FILE_RESET = 'DOWNLOAD_FILE_RESET'

export {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_RESET,
  REGISTER_NOTIFICATION,
  REGISTER_NOTIFICATION_SUCCESS,
  REGISTER_NOTIFICATION_RESET,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_RESET,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_RESET,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  NOTIFICATION_DETAIL_RESET,
  DOWNLOAD_USER_LIST,
  DOWNLOAD_USER_LIST_SUCCESS,
  DOWNLOAD_USER_LIST_RESET,
  UPLOAD_USER_LIST,
  UPLOAD_USER_LIST_SUCCESS,
  UPLOAD_USER_LIST_RESET,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_RESET,
  UPLOAD_ATTACH_FILE,
  UPLOAD_ATTACH_FILE_SUCCESS,
  UPLOAD_ATTACH_FILE_RESET,
  UPLOAD_PDF_FILE,
  UPLOAD_PDF_FILE_SUCCESS,
  UPLOAD_PDF_FILE_RESET,
  RESULT_SEND_MAIL,
  RESULT_SEND_MAIL_SUCCESS,
  RESULT_SEND_MAIL_RESET,
  DOWNLOAD_NOTIFICATION_RESULT,
  DOWNLOAD_NOTIFICATION_RESULT_SUCCESS,
  DOWNLOAD_NOTIFICATION_RESULT_RESET,
  UPDATE_SEND_MAIL,
  UPDATE_SEND_MAIL_SUCCESS,
  UPDATE_SEND_MAIL_RESET,
  EDIT_STATUS_LIST,
  EDIT_STATUS_LIST_SUCCESS,
  EDIT_STATUS_LIST_RESET,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_RESET,
}
