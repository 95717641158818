// Layout auth
import React from 'react'
import { CContainer } from '@coreui/react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AuthLayout = () => {
  const { t } = useTranslation()
  return (
    <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center layout-auth">
      <CContainer>
        <Outlet />
      </CContainer>
      <div className="layout-auth-footer text-center">{t('footer')}</div>
    </div>
  )
}

export default AuthLayout
