import {
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_RESET,
  DELETE_NOTIFICATION_SUCCESS,
  DOWNLOAD_USER_LIST,
  DOWNLOAD_USER_LIST_RESET,
  DOWNLOAD_USER_LIST_SUCCESS,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_RESET,
  GET_NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_DETAIL_RESET,
  REGISTER_NOTIFICATION,
  REGISTER_NOTIFICATION_RESET,
  REGISTER_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_RESET,
  UPDATE_NOTIFICATION_SUCCESS,
  UPLOAD_ATTACH_FILE,
  UPLOAD_ATTACH_FILE_RESET,
  UPLOAD_ATTACH_FILE_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_RESET,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_PDF_FILE,
  UPLOAD_PDF_FILE_RESET,
  UPLOAD_PDF_FILE_SUCCESS,
  UPLOAD_USER_LIST,
  UPLOAD_USER_LIST_RESET,
  UPLOAD_USER_LIST_SUCCESS,
  RESULT_SEND_MAIL,
  RESULT_SEND_MAIL_SUCCESS,
  RESULT_SEND_MAIL_RESET,
  DOWNLOAD_NOTIFICATION_RESULT,
  DOWNLOAD_NOTIFICATION_RESULT_SUCCESS,
  DOWNLOAD_NOTIFICATION_RESULT_RESET,
  UPDATE_SEND_MAIL,
  UPDATE_SEND_MAIL_SUCCESS,
  UPDATE_SEND_MAIL_RESET,
  EDIT_STATUS_LIST,
  EDIT_STATUS_LIST_SUCCESS,
  EDIT_STATUS_LIST_RESET,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_RESET,
} from 'src/redux/actions/notification/ENotificationActions'

const INITIAL_STATE = {
  data: [],
  file_user_list: null,
  file_image: null,
  file: null,
  send_mail: null,
  loading: false,
}

const getNotificationListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_LIST:
      return { ...state, data: [], loading: true }
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GET_NOTIFICATION_LIST_RESET:
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

const getNotificationDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_DETAIL:
      return { ...state, data: {}, loading: true }
    case GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case NOTIFICATION_DETAIL_RESET:
      return {
        ...state,
        data: {},
        loading: false,
      }
    default:
      return state
  }
}

const registerNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_NOTIFICATION:
      return { ...state, data: [], loading: true }
    case REGISTER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case REGISTER_NOTIFICATION_RESET:
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

const updateNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return { ...state, data: [], loading: true }
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case UPDATE_NOTIFICATION_RESET:
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

const deleteNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_NOTIFICATION:
      return { ...state, data: [], loading: true }
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case DELETE_NOTIFICATION_RESET:
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

const downloadUserListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_USER_LIST:
      return { ...state, file_user_list: null, loading: true }
    case DOWNLOAD_USER_LIST_SUCCESS:
      return {
        ...state,
        file_user_list: action.payload,
        loading: false,
      }
    case DOWNLOAD_USER_LIST_RESET:
      return {
        ...state,
        file_user_list: null,
        loading: false,
      }
    default:
      return state
  }
}

const uploadUserListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_USER_LIST:
      return { ...state, file_user_list: null, loading: true }
    case UPLOAD_USER_LIST_SUCCESS:
      return {
        ...state,
        file_user_list: action.payload,
        loading: false,
      }
    case UPLOAD_USER_LIST_RESET:
      return {
        ...state,
        file_user_list: null,
        loading: false,
      }
    default:
      return state
  }
}

const uploadImageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return { ...state, file_image: null, loading: true }
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        file_image: action.payload,
        loading: false,
      }
    case UPLOAD_IMAGE_RESET:
      return {
        ...state,
        file_image: null,
        loading: false,
      }
    default:
      return state
  }
}

const uploadAttachFileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_ATTACH_FILE:
      return { ...state, file: null, loading: true }
    case UPLOAD_ATTACH_FILE_SUCCESS:
      return {
        ...state,
        file: action.payload,
        loading: false,
      }
    case UPLOAD_ATTACH_FILE_RESET:
      return {
        ...state,
        file: null,
        loading: false,
      }
    default:
      return state
  }
}

const uploadPdfFileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_PDF_FILE:
      return { ...state, file: null, loading: true }
    case UPLOAD_PDF_FILE_SUCCESS:
      return {
        ...state,
        file: action.payload,
        loading: false,
      }
    case UPLOAD_PDF_FILE_RESET:
      return {
        ...state,
        file: null,
        loading: false,
      }
    default:
      return state
  }
}

const resultSendMailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESULT_SEND_MAIL:
      return { ...state, send_mail: null, loading: true }
    case RESULT_SEND_MAIL_SUCCESS:
      return {
        ...state,
        send_mail: action.payload,
        loading: false,
      }
    case RESULT_SEND_MAIL_RESET:
      return {
        ...state,
        send_mail: null,
        loading: false,
      }
    default:
      return state
  }
}

const downloadNotificationResultReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_NOTIFICATION_RESULT:
      return { ...state, data: null, loading: true }
    case DOWNLOAD_NOTIFICATION_RESULT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case DOWNLOAD_NOTIFICATION_RESULT_RESET:
      return {
        ...state,
        data: null,
        loading: false,
      }
    default:
      return state
  }
}

const updateSendMailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEND_MAIL:
      return { ...state, data: null, loading: true }
    case UPDATE_SEND_MAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case UPDATE_SEND_MAIL_RESET:
      return {
        ...state,
        data: null,
        loading: false,
      }
    default:
      return state
  }
}

const editStatusListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDIT_STATUS_LIST:
      return { ...state, data: null, loading: true }
    case EDIT_STATUS_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case EDIT_STATUS_LIST_RESET:
      return {
        ...state,
        data: null,
        loading: false,
      }
    default:
      return state
  }
}

const downloadFileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DOWNLOAD_FILE:
      return { ...state, data: null, loading: true }
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case DOWNLOAD_FILE_RESET:
      return {
        ...state,
        data: null,
        loading: false,
      }
    default:
      return state
  }
}

export {
  getNotificationListReducer,
  getNotificationDetailReducer,
  registerNotificationReducer,
  updateNotificationReducer,
  deleteNotificationReducer,
  downloadUserListReducer,
  uploadUserListReducer,
  uploadImageReducer,
  uploadAttachFileReducer,
  uploadPdfFileReducer,
  resultSendMailReducer,
  downloadNotificationResultReducer,
  updateSendMailReducer,
  editStatusListReducer,
  downloadFileReducer,
}
