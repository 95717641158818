import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import {
  ACTION_CHECK_TOKEN_RESET_PASSWORD,
  CHECK_EMAIL_ACTION,
  RESET_PASSWORD_ACTION,
} from 'src/redux/actions/forgot-password/EForgotPasswordActions'
import {
  checkEmailSuccessAction,
  checkEmailTokenResetPasswordSuccessAction,
  resetPasswordSuccessAction,
} from 'src/redux/actions/forgot-password'
import { Endpoint } from '../Endpoint'

function* checkEmailSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.FORGOT_PASSWORD, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(checkEmailSuccessAction(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      checkEmailSuccessAction({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

function* resetPasswordSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.RESET_PASSWORD, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(resetPasswordSuccessAction(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      resetPasswordSuccessAction({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

function* checkEmailTokenResetPassword(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.CHECK_EMAIL_EXIST + `?token=${action?.payload?.token}`)
    }
    const response = yield call(configRequest)
    yield put(checkEmailTokenResetPasswordSuccessAction(response.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      checkEmailTokenResetPasswordSuccessAction({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

export default function* forgotPasswordSaga() {
  yield all([takeLatest(CHECK_EMAIL_ACTION, checkEmailSaga)])
  yield all([takeLatest(RESET_PASSWORD_ACTION, resetPasswordSaga)])
  yield all([takeLatest(ACTION_CHECK_TOKEN_RESET_PASSWORD, checkEmailTokenResetPassword)])
}
