import { createStore, applyMiddleware } from 'redux'
import rootSaga from '../sagas'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware()
const persistConfig = {
  key: 'root',
  storage,
}
const configStore = () =>
  createStore(persistReducer(persistConfig, rootReducer), applyMiddleware(sagaMiddleware))

const store = configStore()
const persistor = persistStore(store)

export { store, persistor }
sagaMiddleware.run(rootSaga)
