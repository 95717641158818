import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import {
  DELETE_ADMIN,
  GET_ADMIN_DETAIL,
  GET_ADMIN_LIST,
  GET_ADMIN_ROLE,
  REGISTER_ADMIN,
  UPDATE_ADMIN,
} from 'src/redux/actions/admin/EAdminActions'
import {
  deleteAdminActionSuccess,
  getAdminDetailActionSuccess,
  getAdminListActionSuccess,
  getAdminRoleActionSuccess,
  registerAdminActionSuccess,
  updateAdminActionSuccess,
} from 'src/redux/actions/admin'
import { Endpoint } from '../Endpoint'
import { generatePath } from 'react-router-dom'

function* submitGetAdminListSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.GET_ADMIN_LIST, { params: action?.payload })
    }
    const response = yield call(configRequest)
    yield put(getAdminListActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getAdminListActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

function* submitGetAdminDetailSaga(action) {
  try {
    const url = generatePath(Endpoint.ADMIN_DETAIL, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.get(url)
    }
    const response = yield call(configRequest)
    yield put(getAdminDetailActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getAdminDetailActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

function* submitRegisterAdminSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.ADMIN_CREATE, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(registerAdminActionSuccess(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      registerAdminActionSuccess({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitDeleteAdminSaga(action) {
  try {
    const url = generatePath(Endpoint.ADMIN_DELETE, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.delete(url)
    }
    const response = yield call(configRequest)
    yield put(deleteAdminActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      deleteAdminActionSuccess({
        status: false,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitUpdateAdminSaga(action) {
  try {
    const url = generatePath(Endpoint.ADMIN_DETAIL, {
      id: action?.payload.id,
    })
    const params = {
      office_id: action?.payload.office_id,
      name: action?.payload.name,
      role_id: action?.payload.role_id,
    }
    const configRequest = () => {
      return instance.put(url, params)
    }
    const response = yield call(configRequest)
    yield put(updateAdminActionSuccess(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      updateAdminActionSuccess({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitGetAdminRoleSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.ADMIN_ROLE, { params: action?.payload })
    }
    const response = yield call(configRequest)
    yield put(getAdminRoleActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getAdminRoleActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

export default function* adminSaga() {
  yield all([takeLatest(GET_ADMIN_DETAIL, submitGetAdminDetailSaga)])
  yield all([takeLatest(GET_ADMIN_LIST, submitGetAdminListSaga)])
  yield all([takeLatest(REGISTER_ADMIN, submitRegisterAdminSaga)])
  yield all([takeLatest(DELETE_ADMIN, submitDeleteAdminSaga)])
  yield all([takeLatest(UPDATE_ADMIN, submitUpdateAdminSaga)])
  yield all([takeLatest(GET_ADMIN_ROLE, submitGetAdminRoleSaga)])
}
