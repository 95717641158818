import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'
import { GET_USER_DETAIL } from 'src/redux/actions/user-detail/userDetailActions'
import { getUserDetailActionSuccess } from 'src/redux/actions/user-detail'
import { generatePath } from 'react-router-dom'

function* getUserDetailSaga(action) {
  try {
    const url = generatePath(Endpoint.GET_USER_DETAIL, {
      mcs_customer_code: action?.payload,
    })
    const configRequest = () => {
      return instance.get(url)
    }
    const response = yield call(configRequest)
    yield put(getUserDetailActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getUserDetailActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

export default function* userDetailSaga() {
  yield all([takeLatest(GET_USER_DETAIL, getUserDetailSaga)])
}
