import { put, takeLatest, all, call } from 'redux-saga/effects'
import instance from 'src/services/api'
import {
  DELETE_NOTIFICATION,
  DOWNLOAD_FILE,
  DOWNLOAD_NOTIFICATION_RESULT,
  DOWNLOAD_USER_LIST,
  EDIT_STATUS_LIST,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_LIST,
  REGISTER_NOTIFICATION,
  RESULT_SEND_MAIL,
  UPDATE_NOTIFICATION,
  UPDATE_SEND_MAIL,
  UPLOAD_ATTACH_FILE,
  UPLOAD_IMAGE,
  UPLOAD_PDF_FILE,
  UPLOAD_USER_LIST,
} from 'src/redux/actions/notification/ENotificationActions'
import {
  getNotificationListActionSuccess,
  getNotificationDetailActionSuccess,
  registerNotificationActionSuccess,
  downloadUserListSuccess,
  uploadUserListSuccess,
  uploadImageSuccess,
  uploadAttachFileSuccess,
  updateNotificationActionSuccess,
  deleteNotificationActionSuccess,
  uploadPdfFileSuccess,
  downloadNotificationResultSuccess,
  resultSendMailSuccess,
  updateSendMailSuccess,
  editStatusListSuccess,
} from 'src/redux/actions/notification'
import { Endpoint } from '../Endpoint'
import { generatePath } from 'react-router-dom'
import { ResponseStatus } from 'src/utils/ResponseStatus'
import { formatDateTimeURI } from 'src/utils/format'

function* submitGetNotificationListSaga(action) {
  try {
    let newParams = action?.payload
    const status_notice = action?.payload?.status_notice
    const isFullStatus =
      status_notice?.includes('1') && status_notice?.includes('2') && status_notice?.includes('3')
    if (isFullStatus) {
      newParams = {
        ...newParams,
        status_notice: '',
      }
    }
    const configRequest = () => {
      return instance.get(Endpoint.NOTIFICATIONS_LIST, { params: newParams })
    }
    const response = yield call(configRequest)
    yield put(getNotificationListActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getNotificationListActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

function* submitGetNotificationDetailSaga(action) {
  try {
    const url = generatePath(Endpoint.NOTIFICATION_DETAIL, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.get(url)
    }
    const response = yield call(configRequest)
    yield put(getNotificationDetailActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getNotificationDetailActionSuccess({
        status: false,
        message: msg,
        code: code,
      }),
    )
  }
}

function* submitRegisterNotificationSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.NOTIFICATION_CREATE, action?.payload)
    }
    const response = yield call(configRequest)
    const newResponse = {
      ...response?.data,
      isDraft: action?.payload?.graph_flag,
    }
    yield put(registerNotificationActionSuccess(newResponse))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      registerNotificationActionSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
        isDraft: action?.payload?.graph_flag,
      }),
    )
  }
}

function* submitUpdateNotificationSaga(action) {
  try {
    const url = generatePath(Endpoint.NOTIFICATION_UPDATE, {
      id: action?.payload?.id,
    })
    const configRequest = () => {
      return instance.put(url, action?.payload?.data)
    }
    const response = yield call(configRequest)
    const newResponse = {
      ...response?.data,
      isDraft: action?.payload?.data?.graph_flag,
    }
    yield put(updateNotificationActionSuccess(newResponse))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      updateNotificationActionSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
        isDraft: action?.payload?.data?.graph_flag,
      }),
    )
  }
}

function* submitDeleteNotificationSaga(action) {
  try {
    const url = generatePath(Endpoint.NOTIFICATION_DELETE, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.delete(url)
    }
    const response = yield call(configRequest)
    yield put(deleteNotificationActionSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      deleteNotificationActionSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitDownloadUserListSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.DOWNLOAD_USER_LIST, { params: action?.payload })
    }
    const response = yield call(configRequest)
    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileURL
    const nameFile =
      action?.payload?.shop_code + '_user list_' + formatDateTimeURI(new Date()) + '.csv'
    link.setAttribute('download', nameFile)
    document.body.appendChild(link)
    link.click()
    yield put(downloadUserListSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      downloadUserListSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitUploadUserListSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.UPLOAD_USER_LIST, { params: action?.payload })
    }
    const response = yield call(configRequest)
    yield put(uploadUserListSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      uploadUserListSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitUploadImageSaga(action) {
  try {
    const formData = new FormData()
    formData.append('image', action?.payload?.image)
    formData.append('type_image', action?.payload?.type_image)
    const configRequest = () => {
      return instance.postForm(Endpoint.NOTIFICATION_UPLOAD_IMAGE, formData)
    }
    const response = yield call(configRequest)
    const newResponse = {
      ...response?.data,
      type_image: action?.payload?.type_image,
    }
    yield put(uploadImageSuccess(newResponse))
  } catch (error) {
    const code = error?.response?.data?.error?.code
    const message = error?.response?.data?.error?.message
    yield put(
      uploadImageSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: message,
        type_image: action?.payload?.type_image,
      }),
    )
  }
}

function* submitUploadAttachFileSaga(action) {
  try {
    const formData = new FormData()
    formData.append('file', action?.payload?.image)
    formData.append('type_file', action?.payload?.type_file)
    const configRequest = () => {
      return instance.postForm(Endpoint.NOTIFICATION_UPLOAD_ATTACH_FILE, formData)
    }
    const response = yield call(configRequest)
    const newResponse = {
      ...response?.data,
      type_file: action?.payload?.type_file,
    }
    yield put(uploadAttachFileSuccess(newResponse))
  } catch (error) {
    const code = error?.response?.data?.error?.code
    const message = error?.response?.data?.error?.message
    yield put(
      uploadAttachFileSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: message,
        type_file: action?.payload?.type_file,
      }),
    )
  }
}

function* submitUploadPdfFileSaga(action) {
  try {
    const formData = new FormData()
    formData.append('file', action?.payload?.file)
    const configRequest = () => {
      return instance.postForm(Endpoint.NOTIFICATION_UPLOAD_PDF_FILE, formData)
    }
    const response = yield call(configRequest)
    yield put(uploadPdfFileSuccess(response?.data))
  } catch (error) {
    const code = error?.response?.data?.error?.code
    const message = error?.response?.data?.error?.message
    yield put(
      uploadPdfFileSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: message,
      }),
    )
  }
}

function* submitResultSendMailSaga(action) {
  try {
    const configRequest = () => {
      return instance.post(Endpoint.RESULT_SEND_MAIL, action?.payload)
    }
    const response = yield call(configRequest)
    yield put(resultSendMailSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      resultSendMailSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitDownloadNotificationResultSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.NOTIFICATION_DOWNLOAD_RESULT, { params: action?.payload })
    }
    const response = yield call(configRequest)
    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileURL
    const nameFile = action?.title + '_send result_' + formatDateTimeURI(new Date()) + '.csv'
    link.setAttribute('download', nameFile)
    document.body.appendChild(link)
    link.click()
    link.remove()
    URL.revokeObjectURL(fileURL)
    yield put(downloadNotificationResultSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      downloadNotificationResultSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitUpdateSendMailSaga(action) {
  try {
    const url = generatePath(Endpoint.UPDATE_SEND_MAIL, {
      id: action?.payload,
    })
    const configRequest = () => {
      return instance.put(url, action?.payload?.data)
    }
    const response = yield call(configRequest)
    yield put(updateSendMailSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      updateSendMailSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitEditStatusListSaga(action) {
  try {
    const url = generatePath(Endpoint.UPDATE_FLAG_NOTICE, {
      id: action?.payload?.id,
    })
    const configRequest = () => {
      return instance.put(url, action?.payload?.data)
    }
    const response = yield call(configRequest)
    yield put(editStatusListSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      editStatusListSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

function* submitDownloadFileSaga(action) {
  try {
    const configRequest = () => {
      return instance.get(Endpoint.NOTIFICATION_DOWNLOAD_FILE, { params: action?.payload })
    }
    const response = yield call(configRequest)
    const file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', action?.title)
    document.body.appendChild(link)
    link.click()
    link.remove()
    URL.revokeObjectURL(fileURL)
    yield put(downloadNotificationResultSuccess(response?.data))
  } catch (error) {
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      downloadNotificationResultSuccess({
        status: ResponseStatus.NG,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* requestSaga() {
  yield all([takeLatest(GET_NOTIFICATION_LIST, submitGetNotificationListSaga)])
  yield all([takeLatest(GET_NOTIFICATION_DETAIL, submitGetNotificationDetailSaga)])
  yield all([takeLatest(REGISTER_NOTIFICATION, submitRegisterNotificationSaga)])
  yield all([takeLatest(UPDATE_NOTIFICATION, submitUpdateNotificationSaga)])
  yield all([takeLatest(DELETE_NOTIFICATION, submitDeleteNotificationSaga)])
  yield all([takeLatest(DOWNLOAD_USER_LIST, submitDownloadUserListSaga)])
  yield all([takeLatest(UPLOAD_USER_LIST, submitUploadUserListSaga)])
  yield all([takeLatest(UPLOAD_IMAGE, submitUploadImageSaga)])
  yield all([takeLatest(UPLOAD_ATTACH_FILE, submitUploadAttachFileSaga)])
  yield all([takeLatest(UPLOAD_PDF_FILE, submitUploadPdfFileSaga)])
  yield all([takeLatest(RESULT_SEND_MAIL, submitResultSendMailSaga)])
  yield all([takeLatest(DOWNLOAD_NOTIFICATION_RESULT, submitDownloadNotificationResultSaga)])
  yield all([takeLatest(UPDATE_SEND_MAIL, submitUpdateSendMailSaga)])
  yield all([takeLatest(EDIT_STATUS_LIST, submitEditStatusListSaga)])
  yield all([takeLatest(DOWNLOAD_FILE, submitDownloadFileSaga)])
}
