import { combineReducers } from 'redux'
import sidebarState from './sidebarState'
import loginReducer from './login'
import forgotPasswordReducer from './forgot-password'
import {
  getRequestListReducer,
  getRequestDetailReducer,
  updateRequestDetailReducer,
} from './request'
import {
  getNotificationListReducer,
  getNotificationDetailReducer,
  registerNotificationReducer,
  updateNotificationReducer,
  deleteNotificationReducer,
  downloadUserListReducer,
  uploadUserListReducer,
  uploadImageReducer,
  uploadAttachFileReducer,
  uploadPdfFileReducer,
  resultSendMailReducer,
  downloadNotificationResultReducer,
  updateSendMailReducer,
  editStatusListReducer,
  downloadFileReducer,
} from './notification'
import {
  getAdminListReducer,
  registerAdminReducer,
  deleteAdminReducer,
  updateAdminReducer,
  getAdminDetailReducer,
  getAdminRoleReducer,
} from './admin'
import { getOfficeListReducer } from './office'
import { getUserDetailReducer } from './user-detail'

const allReducers = combineReducers({
  sidebarState: sidebarState,
  loginReducer: loginReducer,
  forgotPasswordReducer: forgotPasswordReducer,
  getRequestListReducer: getRequestListReducer,
  getRequestDetailReducer: getRequestDetailReducer,
  updateRequestDetailReducer: updateRequestDetailReducer,
  getNotificationListReducer: getNotificationListReducer,
  getNotificationDetailReducer: getNotificationDetailReducer,
  registerNotificationReducer: registerNotificationReducer,
  updateNotificationReducer: updateNotificationReducer,
  deleteNotificationReducer: deleteNotificationReducer,
  getAdminListReducer: getAdminListReducer,
  registerAdminReducer: registerAdminReducer,
  deleteAdminReducer: deleteAdminReducer,
  updateAdminReducer: updateAdminReducer,
  getOfficeListReducer: getOfficeListReducer,
  getUserDetailReducer: getUserDetailReducer,
  getAdminDetailReducer: getAdminDetailReducer,
  getAdminRoleReducer: getAdminRoleReducer,
  downloadUserListReducer: downloadUserListReducer,
  uploadUserListReducer: uploadUserListReducer,
  uploadImageReducer: uploadImageReducer,
  uploadAttachFileReducer: uploadAttachFileReducer,
  uploadPdfFileReducer: uploadPdfFileReducer,
  resultSendMailReducer: resultSendMailReducer,
  downloadNotificationResultReducer: downloadNotificationResultReducer,
  updateSendMailReducer: updateSendMailReducer,
  editStatusListReducer: editStatusListReducer,
  downloadFileReducer: downloadFileReducer,
})

// eslint-disable-next-line no-undef
const rootReducer = (state, action) => {
  return allReducers(state, action)
}

export default rootReducer
