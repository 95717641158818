import { BASE_URL } from 'src/utils/constants'
import axios from 'axios'
import { Key } from 'src/helpers/KeyStorage'
import { ResponseError } from 'src/utils/ResponseError'
import { Auth } from 'src/helpers/Auth'
import { store } from 'src/redux/store'
import { logoutNotAllowAction } from 'src/redux/actions/login'

const defaultOptions = {
  baseURL: BASE_URL,
  // baseURL: 'http://localhost:3001/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
}

const instance = axios.create(defaultOptions)
//
// export const configInstance = (config) => {
//   if (config.data instanceof FormData) {
//     config.headers['Content-Type'] = 'multipart/form-data'
//     return config
//   }
//   config.data = mapKeys(config.data, (_, key) => snakeCase(key))
//   return config
// }
//
// instance.interceptors.request.use(configInstance)

// Set the AUTH token for any request
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(Key.token)
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response && error?.response?.data) {
      const code = error?.response?.data?.error?.code
      if (
        code === ResponseError.NO_AUTHENTICATED_ERROR.code ||
        code === ResponseError.NOT_ALLOW.code
      ) {
        // no auth
        Auth.removeToken()
        store.dispatch(logoutNotAllowAction())
        return null
      } else if (
        code >= ResponseError.SYSTEM_ERROR_500.code &&
        code <= ResponseError.SYSTEM_ERROR_510.code
      ) {
        window.dispatchEvent(new CustomEvent('server_error', { detail: true }))
        return null
      }
    }
    return Promise.reject(error)
  },
)

export default instance
