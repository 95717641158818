import {
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_RESET,
  DELETE_NOTIFICATION_SUCCESS,
  DOWNLOAD_USER_LIST,
  DOWNLOAD_USER_LIST_RESET,
  DOWNLOAD_USER_LIST_SUCCESS,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_RESET,
  GET_NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_DETAIL_RESET,
  REGISTER_NOTIFICATION,
  REGISTER_NOTIFICATION_RESET,
  REGISTER_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_RESET,
  UPDATE_NOTIFICATION_SUCCESS,
  UPLOAD_ATTACH_FILE,
  UPLOAD_ATTACH_FILE_RESET,
  UPLOAD_ATTACH_FILE_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_RESET,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_PDF_FILE,
  UPLOAD_PDF_FILE_RESET,
  UPLOAD_PDF_FILE_SUCCESS,
  UPLOAD_USER_LIST,
  UPLOAD_USER_LIST_RESET,
  UPLOAD_USER_LIST_SUCCESS,
  RESULT_SEND_MAIL,
  RESULT_SEND_MAIL_SUCCESS,
  RESULT_SEND_MAIL_RESET,
  DOWNLOAD_NOTIFICATION_RESULT,
  DOWNLOAD_NOTIFICATION_RESULT_SUCCESS,
  DOWNLOAD_NOTIFICATION_RESULT_RESET,
  UPDATE_SEND_MAIL,
  UPDATE_SEND_MAIL_SUCCESS,
  UPDATE_SEND_MAIL_RESET,
  EDIT_STATUS_LIST,
  EDIT_STATUS_LIST_SUCCESS,
  EDIT_STATUS_LIST_RESET,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_RESET,
} from './ENotificationActions'

export const getNotificationListAction = (payload) => {
  return {
    type: GET_NOTIFICATION_LIST,
    payload,
  }
}

export const getNotificationListActionSuccess = (payload) => {
  return {
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload,
  }
}

export const resetNotificationListAction = (payload) => {
  return {
    type: GET_NOTIFICATION_LIST_RESET,
    payload,
  }
}

export const getNotificationDetailAction = (payload) => {
  return {
    type: GET_NOTIFICATION_DETAIL,
    payload,
  }
}

export const getNotificationDetailActionSuccess = (payload) => {
  return {
    type: GET_NOTIFICATION_DETAIL_SUCCESS,
    payload,
  }
}

export const resetNotificationDetailAction = (payload) => {
  return {
    type: NOTIFICATION_DETAIL_RESET,
    payload,
  }
}

export const registerNotificationAction = (payload) => {
  return {
    type: REGISTER_NOTIFICATION,
    payload,
  }
}

export const registerNotificationActionSuccess = (payload) => {
  return {
    type: REGISTER_NOTIFICATION_SUCCESS,
    payload,
  }
}

export const resetRegisterNotificationAction = (payload) => {
  return {
    type: REGISTER_NOTIFICATION_RESET,
    payload,
  }
}

export const updateNotificationAction = (payload) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload,
  }
}

export const updateNotificationActionSuccess = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload,
  }
}

export const resetUpdateNotificationAction = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_RESET,
    payload,
  }
}

export const deleteNotificationAction = (payload) => {
  return {
    type: DELETE_NOTIFICATION,
    payload,
  }
}

export const deleteNotificationActionSuccess = (payload) => {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload,
  }
}

export const resetDeleteNotificationAction = (payload) => {
  return {
    type: DELETE_NOTIFICATION_RESET,
    payload,
  }
}

export const downloadUserListAction = (payload) => {
  return {
    type: DOWNLOAD_USER_LIST,
    payload,
  }
}

export const downloadUserListSuccess = (payload) => {
  return {
    type: DOWNLOAD_USER_LIST_SUCCESS,
    payload,
  }
}

export const resetDownloadUserList = (payload) => {
  return {
    type: DOWNLOAD_USER_LIST_RESET,
    payload,
  }
}

export const uploadUserListAction = (payload) => {
  return {
    type: UPLOAD_USER_LIST,
    payload,
  }
}

export const uploadUserListSuccess = (payload) => {
  return {
    type: UPLOAD_USER_LIST_SUCCESS,
    payload,
  }
}

export const resetUploadUserList = (payload) => {
  return {
    type: UPLOAD_USER_LIST_RESET,
    payload,
  }
}

export const uploadImageAction = (payload) => {
  return {
    type: UPLOAD_IMAGE,
    payload,
  }
}

export const uploadImageSuccess = (payload) => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    payload,
  }
}

export const resetUploadImage = (payload) => {
  return {
    type: UPLOAD_IMAGE_RESET,
    payload,
  }
}

export const uploadAttachFileAction = (payload) => {
  return {
    type: UPLOAD_ATTACH_FILE,
    payload,
  }
}

export const uploadAttachFileSuccess = (payload) => {
  return {
    type: UPLOAD_ATTACH_FILE_SUCCESS,
    payload,
  }
}

export const resetUploadAttachFile = (payload) => {
  return {
    type: UPLOAD_ATTACH_FILE_RESET,
    payload,
  }
}

export const uploadPdfFileAction = (payload) => {
  return {
    type: UPLOAD_PDF_FILE,
    payload,
  }
}

export const uploadPdfFileSuccess = (payload) => {
  return {
    type: UPLOAD_PDF_FILE_SUCCESS,
    payload,
  }
}

export const resetUploadPdfFile = (payload) => {
  return {
    type: UPLOAD_PDF_FILE_RESET,
    payload,
  }
}

export const resultSendMailAction = (payload) => {
  return {
    type: RESULT_SEND_MAIL,
    payload,
  }
}

export const resultSendMailSuccess = (payload) => {
  return {
    type: RESULT_SEND_MAIL_SUCCESS,
    payload,
  }
}

export const resetResultSendMail = (payload) => {
  return {
    type: RESULT_SEND_MAIL_RESET,
    payload,
  }
}

export const downloadNotificationResultAction = (payload, title) => {
  return {
    type: DOWNLOAD_NOTIFICATION_RESULT,
    payload,
    title,
  }
}

export const downloadNotificationResultSuccess = (payload) => {
  return {
    type: DOWNLOAD_NOTIFICATION_RESULT_SUCCESS,
    payload,
  }
}

export const resetDownloadNotificationResult = (payload) => {
  return {
    type: DOWNLOAD_NOTIFICATION_RESULT_RESET,
    payload,
  }
}

export const updateSendMailAction = (payload) => {
  return {
    type: UPDATE_SEND_MAIL,
    payload,
  }
}

export const updateSendMailSuccess = (payload) => {
  return {
    type: UPDATE_SEND_MAIL_SUCCESS,
    payload,
  }
}

export const resetUpdateSendMail = (payload) => {
  return {
    type: UPDATE_SEND_MAIL_RESET,
    payload,
  }
}

export const editStatusListAction = (payload) => {
  return {
    type: EDIT_STATUS_LIST,
    payload,
  }
}

export const editStatusListSuccess = (payload) => {
  return {
    type: EDIT_STATUS_LIST_SUCCESS,
    payload,
  }
}

export const resetEditStatusList = (payload) => {
  return {
    type: EDIT_STATUS_LIST_RESET,
    payload,
  }
}

export const downloadFileAction = (payload, title) => {
  return {
    type: DOWNLOAD_FILE,
    payload,
    title,
  }
}

export const downloadFileSuccess = (payload) => {
  return {
    type: DOWNLOAD_FILE_SUCCESS,
    payload,
  }
}

export const resetDownloadFile = (payload) => {
  return {
    type: DOWNLOAD_FILE_RESET,
    payload,
  }
}
