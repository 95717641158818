import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'
import { AdminSidebarNav } from './AdminSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import navigation from 'src/nav/index'
import logo from 'src/assets/images/logoLogin.svg'
import logo1 from 'src/assets/images/logo.svg'

const AdminSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarState.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarState.sidebarShow)
  const moduleAccess = useSelector((state) => state.loginReducer.moduleAccess)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex sidebar-header" to="/">
        <img src={logo} alt="" className="sidebar-brand-full" />
        <img src={logo1} alt="" className="sidebar-brand-narrow" />
      </CSidebarBrand>
      <CSidebarNav className="sidebar-main">
        <SimpleBar>
          <AdminSidebarNav items={navigation} moduleAccess={moduleAccess} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(AdminSidebar)
