import {
  DELETE_ADMIN,
  DELETE_ADMIN_RESET,
  DELETE_ADMIN_SUCCESS,
  GET_ADMIN_DETAIL,
  GET_ADMIN_DETAIL_RESET,
  GET_ADMIN_DETAIL_SUCCESS,
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_RESET,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_ROLE,
  GET_ADMIN_ROLE_RESET,
  GET_ADMIN_ROLE_SUCCESS,
  REGISTER_ADMIN,
  REGISTER_ADMIN_RESET,
  REGISTER_ADMIN_SUCCESS,
  UPDATE_ADMIN,
  UPDATE_ADMIN_RESET,
  UPDATE_ADMIN_SUCCESS,
} from 'src/redux/actions/admin/EAdminActions'

const INITIAL_STATE = {
  data: [],
  roleList: [],
  loading: false,
  adminDetail: {},
  dataUpdate: {},
  dataRegister: {},
}

const getAdminDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_DETAIL:
      return { ...state, adminDetail: {}, loading: true }
    case GET_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        adminDetail: action.payload,
        loading: false,
      }
    case GET_ADMIN_DETAIL_RESET:
      return {
        ...state,
        adminDetail: {},
        loading: false,
      }
    default:
      return state
  }
}

const getAdminListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_LIST:
      return { ...state, data: [], loading: true }
    case GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GET_ADMIN_LIST_RESET:
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

const registerAdminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_ADMIN:
      return { ...state, dataRegister: {}, loading: true }
    case REGISTER_ADMIN_SUCCESS:
      return {
        ...state,
        dataRegister: action.payload,
        loading: false,
      }
    case REGISTER_ADMIN_RESET:
      return {
        ...state,
        dataRegister: {},
        loading: false,
      }
    default:
      return state
  }
}

const deleteAdminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_ADMIN:
      return { ...state, data: [], loading: true }
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case DELETE_ADMIN_RESET:
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

const updateAdminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ADMIN:
      return { ...state, dataUpdate: {}, loading: true }
    case UPDATE_ADMIN_RESET:
      return { ...state, dataUpdate: {}, loading: false }
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

const getAdminRoleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_ROLE:
      return { ...state, roleList: {}, loading: true }
    case GET_ADMIN_ROLE_RESET:
      return { ...state, roleList: {}, loading: false }
    case GET_ADMIN_ROLE_SUCCESS:
      return {
        ...state,
        roleList: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export {
  getAdminListReducer,
  registerAdminReducer,
  deleteAdminReducer,
  updateAdminReducer,
  getAdminDetailReducer,
  getAdminRoleReducer,
}
