import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import AdminRouter from './router/AdminRouter'
import AuthRouter from './router/AuthRouter'
import { useSelector } from 'react-redux'
import Modal from './components/common/modal/Modal'
import { caching } from './utils/caching'
import { useTranslation } from 'react-i18next'

const App = () => {
  const { t } = useTranslation()

  const user = useSelector((state) => state.loginReducer)
  const [isShowModal, setShowModal] = useState(false)

  useEffect(() => {
    caching()
  })

  useEffect(() => {
    window.addEventListener('server_error', handleShowModal)
    return () => {
      window.removeEventListener('server_error', handleShowModal)
    }
  }, [])

  const handleShowModal = (event) => {
    if (event?.detail) {
      setShowModal(true)
    }
  }

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  const renderModalCommon = () => {
    return (
      <Modal
        visible={isShowModal}
        onClose={() => setShowModal(false)}
        onSubmit={() => setShowModal(false)}
        content={t('modal.txt_500')}
        txtClose={t('modal.btn_cancel')}
        isSmall={true}
      />
    )
  }

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        {user?.data?.result?.token !== undefined ? <AdminRouter /> : <AuthRouter />}
        {renderModalCommon()}
      </Suspense>
    </BrowserRouter>
  )
}

export default App
