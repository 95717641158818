import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom'
import routes from './routes'
import AdminLayout from 'src/views/layout/AdminLayout'
import Modal from '../components/common/modal/Modal'
import { useTranslation } from 'react-i18next'
import router from './strings'
import { logoutAction } from '../redux/actions/login'
import { useDispatch, useSelector } from 'react-redux'
import { TIME_OUT } from '../utils/strings'
import ScrollTop from '../helpers/ScrollTop'

const AdminRouter = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const adminEmail = useSelector((state) => state.loginReducer?.data?.result?.user?.email)
  const [isShowModalLogout, setShowModalLogout] = useState({
    isShow: false,
    token: '',
  })

  useEffect(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search)
      const token = searchParams?.get('token')
      if (token) {
        setShowModalLogout({
          isShow: true,
          token: token,
        })
      }
    }
    // eslint-disable-next-line
  }, [])

  const renderModalConfirmLogout = () => {
    const handleSubmit = () => {
      dispatch(logoutAction())
      setTimeout(() => {
        navigate(`${router.RESET_PASSWORD}?token=${isShowModalLogout?.token}`)
      }, TIME_OUT.DELAY_MODAL)
    }

    return (
      <Modal
        isTwoButton
        visible={isShowModalLogout?.isShow}
        onClose={() =>
          setShowModalLogout({
            isShow: false,
            token: '',
          })
        }
        onSubmit={handleSubmit}
        content={adminEmail + t('modal.confirm_logout')}
        txtClose={t('modal.btn_cancel')}
        txtOK={t('modal.btn_logout')}
        isSmall={true}
      />
    )
  }

  return (
    <>
      <ScrollTop />
      <Routes>
        <Route element={<AdminLayout />}>
          {routes.map((route, idx) => {
            if (!route.auth) {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              )
            }
            return null
          })}
          <Route path="*" element={<Navigate to="request/list" replace />} />
        </Route>
      </Routes>
      {renderModalConfirmLogout()}
    </>
  )
}

export default React.memo(AdminRouter)
