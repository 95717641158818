import {
  GET_REQUEST_LIST,
  GET_REQUEST_LIST_SUCCESS,
  GET_REQUEST_DETAIL,
  GET_REQUEST_DETAIL_SUCCESS,
  GET_REQUEST_DETAIL_RESET,
  UPDATE_REQUEST_DETAIL,
  UPDATE_REQUEST_DETAIL_SUCCESS,
  UPDATE_REQUEST_DETAIL_RESET,
  GET_REQUEST_LIST_RESET,
} from 'src/redux/actions/request/ERequestActions'

const INITIAL_STATE = {
  data: [],
  loading: false,
}

const getRequestListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REQUEST_LIST:
      return { ...state, data: [], loading: true }
    case GET_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case GET_REQUEST_LIST_RESET:
      return {
        ...state,
        data: [],
        loading: false,
      }
    default:
      return state
  }
}

const getRequestDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REQUEST_DETAIL:
      return { data: {}, loading: true }
    case GET_REQUEST_DETAIL_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      }
    case GET_REQUEST_DETAIL_RESET:
      return {
        data: {},
        loading: false,
      }
    default:
      return state
  }
}

const updateRequestDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_REQUEST_DETAIL:
      return { data: {}, loading: true }
    case UPDATE_REQUEST_DETAIL_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      }
    case UPDATE_REQUEST_DETAIL_RESET:
      return {
        data: {},
        loading: false,
      }
    default:
      return state
  }
}

export { getRequestListReducer, getRequestDetailReducer, updateRequestDetailReducer }
