import { Key } from './KeyStorage'

const getToken = () => {
  const token = window.localStorage.getItem(Key.token)
  if (token) {
    return token
  }
  return null
}

const setToken = (token) => {
  window.localStorage.setItem(Key.token, token)
}

const removeToken = () => {
  window.localStorage.removeItem(Key.token)
  window.localStorage.removeItem(Key.profile)
}

export const Auth = { getToken, setToken, removeToken }
