import {
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_RESET,
} from './userDetailActions.js'

export const getUserDetailAction = (payload) => {
  return {
    type: GET_USER_DETAIL,
    payload,
  }
}

export const getUserDetailActionSuccess = (payload) => {
  return {
    type: GET_USER_DETAIL_SUCCESS,
    payload,
  }
}

export const resetUserDetailAction = (payload) => {
  return {
    type: GET_USER_DETAIL_RESET,
    payload,
  }
}
