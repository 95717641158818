const GET_ADMIN_LIST = 'GET_ADMIN_LIST'
const GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS'
const GET_ADMIN_LIST_RESET = 'GET_ADMIN_LIST_RESET'
const REGISTER_ADMIN = 'REGISTER_ADMIN'
const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS'
const REGISTER_ADMIN_RESET = 'REGISTER_ADMIN_RESET'
const DELETE_ADMIN = 'DELETE_ADMIN'
const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
const DELETE_ADMIN_RESET = 'DELETE_ADMIN_RESET'
const UPDATE_ADMIN = 'UPDATE_ADMIN'
const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS'
const UPDATE_ADMIN_RESET = 'UPDATE_ADMIN_RESET'
const GET_ADMIN_DETAIL = 'GET_ADMIN_DETAIL'
const GET_ADMIN_DETAIL_SUCCESS = 'GET_ADMIN_DETAIL_SUCCESS'
const GET_ADMIN_DETAIL_RESET = 'GET_ADMIN_DETAIL_RESET'
const GET_ADMIN_ROLE = 'GET_ADMIN_ROLE'
const GET_ADMIN_ROLE_SUCCESS = 'GET_ADMIN_ROLE_SUCCESS'
const GET_ADMIN_ROLE_RESET = 'GET_ADMIN_ROLE_RESET'

export {
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_LIST_RESET,
  REGISTER_ADMIN,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_RESET,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_RESET,
  UPDATE_ADMIN,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_RESET,
  GET_ADMIN_DETAIL,
  GET_ADMIN_DETAIL_SUCCESS,
  GET_ADMIN_DETAIL_RESET,
  GET_ADMIN_ROLE,
  GET_ADMIN_ROLE_SUCCESS,
  GET_ADMIN_ROLE_RESET,
}
