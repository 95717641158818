import { all, fork } from 'redux-saga/effects'
import loginSaga from './login'
import forgotPasswordSaga from './forgot-password'
import requestSaga from './request'
import notificationSaga from './notification'
import adminSaga from './admin'
import officeSaga from './office'
import userDetailSaga from './user-detail'

export default function* rootSaga() {
  yield all([fork(loginSaga)])
  yield all([fork(forgotPasswordSaga)])
  yield all([fork(requestSaga)])
  yield all([fork(notificationSaga)])
  yield all([fork(adminSaga)])
  yield all([fork(officeSaga)])
  yield all([fork(userDetailSaga)])
}
