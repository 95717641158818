import { GET_OFFICE_LIST, GET_OFFICE_LIST_SUCCESS } from 'src/redux/actions/office/officeActions'

const INITIAL_STATE = {
  data: [],
  loading: false,
}

const getOfficeListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_OFFICE_LIST:
      return { ...state, data: [], loading: true }
    case GET_OFFICE_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export { getOfficeListReducer }
