import React from 'react'
import { CFooter } from '@coreui/react'

const AdminFooter = () => {
  return (
    <CFooter>
      <div>
        <span className="ms-1 font-size-13 color-333333">
          Copyright &copy; 2022 MITSUWA SANGYO CO., Ltd. All Rights Reserved.
        </span>
      </div>
    </CFooter>
  )
}

export default React.memo(AdminFooter)
