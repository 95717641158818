import {
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_RESET,
} from 'src/redux/actions/user-detail/userDetailActions'

const INITIAL_STATE = {
  data: null,
  loading: false,
}

const getUserDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_DETAIL:
      return { ...state, data: null, loading: true }
    case GET_USER_DETAIL_RESET:
      return { ...state, data: null, loading: false }
    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export { getUserDetailReducer }
